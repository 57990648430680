import { FC } from 'react';
import { ReferenceField, Show, TabbedShowLayout, TextField } from 'react-admin';
import { RESOURCE_ROLES_RA } from '../../provider/restProvider';
import { ResourceField } from './ResourceField';
import { commonStyles } from '../CommonStyles';
import { ShowPropsWithLocation } from '../types';

export const PermissionShow: FC<ShowPropsWithLocation> = ({
  syncWithLocation,
  ...props
}) => {
  return (
    <Show
      title={syncWithLocation === undefined ? 'Role Permission' : ' '}
      actions={false}
      {...props}
    >
      <TabbedShowLayout>
        <TabbedShowLayout.Tab
          syncWithLocation={syncWithLocation}
          label='Role Permission'
        >
          <TextField
            source='id'
            label='ID'
            sx={commonStyles.formComponentDefaultMargins}
          />
          <ReferenceField
            source='hrRoleId'
            reference={RESOURCE_ROLES_RA}
            label='Role'
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextField
            source='permissionType'
            label='Permission Type'
            sx={commonStyles.formComponentDefaultMargins}
          />
          <ResourceField
            source='resource'
            label='Resource'
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextField
            source='action'
            label='Role Action'
            sx={commonStyles.formComponentDefaultMargins}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
