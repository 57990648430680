import moment from 'moment/moment';
import { DEFAULT_DATE_FORMAT } from '../../provider/constants';

export const ALL_1970 = 'Jan/01/1970';
export const ALL_2099 = 'Jan/01/2099';
export const pastChoices = [
  {
    id: moment().subtract(1, 'months').format(DEFAULT_DATE_FORMAT),
    name: '1 Month',
  },
  {
    id: moment().subtract(3, 'months').format(DEFAULT_DATE_FORMAT),
    name: '3 Months',
  },
  {
    id: moment().subtract(5, 'months').format(DEFAULT_DATE_FORMAT),
    name: '5 Months',
  },
];

export const featureChoices = [
  {
    id: moment().add(1, 'months').format(DEFAULT_DATE_FORMAT),
    name: '1 Month',
  },
  {
    id: moment().add(3, 'months').format(DEFAULT_DATE_FORMAT),
    name: '3 Months',
  },
  {
    id: moment().add(5, 'months').format(DEFAULT_DATE_FORMAT),
    name: '5 Months',
  },
];
