import { FC } from 'react';
import { SaveButton, Toolbar, ToolbarProps } from 'react-admin';
import { UserCreateType } from './types';

type UserFormToolbarProps = Omit<ToolbarProps, 'children'>;

export const UserFormToolbar: FC<UserFormToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        type='button'
        transform={transformUserObject}
      />
    </Toolbar>
  );
};

function transformUserObject(data: UserCreateType) {
  return ({
    ...data,
    fieldManagerRecord: {
      ...data?.fieldManagerRecord,
      name: `${data?.firstName ?? ''} ${data?.lastName ?? ''}`.trim(),
      phone: data?.phone,
      email: data?.email,
    },
  });
}
