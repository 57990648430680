import * as React from 'react';
import { useState } from 'react';
import {
  Datagrid,
  Form,
  ListContextProvider,
  ListView,
  ResourceContextProvider,
  SelectInput,
  SortPayload,
  TextField,
  useGetList,
  useListContext,
} from 'react-admin';
import {
  RESOURCE_CANDIDATES,
  RESOURCE_PLACEMENTS,
} from '../../provider/restProvider';
import LinkField from '../LinkField';
import Box from '@mui/material/Box';
import { convertDateToStr } from '../../provider/UtilityFunctions';
import { ListControllerResult } from 'ra-core/dist/cjs/controller/list/useListController';
import { ALL_2099, featureChoices } from './constants';
import { styles } from './styles';
import { FilterFormTitle } from './FilterFormTitle';

const FilterForm = () => {
  const { filterValues, setFilters } = useListContext();

  const onChange = (event) => {
    setFilters(
      {
        endDate: {
          fieldName: 'endDate',
          fieldValue: event.target.value as string,
          condition: '<=',
        },
      },
      { creationDate: true }
    );
  };

  return (
    <Box display="flex" justifyContent="space-between" m={0} width="100%">
      <FilterFormTitle>Placements Ending Soon</FilterFormTitle>
      <Form onSubmit={() => {}}>
        <SelectInput
          label="Ending within"
          source="endDate"
          margin="dense"
          choices={featureChoices}
          sx={styles.selectInput}
          defaultValue={filterValues.endDate.fieldValue}
          emptyText="All"
          emptyValue={ALL_2099}
          onChange={onChange}
        />
      </Form>
    </Box>
  );
};

export const PlacementEnding: React.FC = () => {
  const today = convertDateToStr(new Date());
  const defaultFilters = {
    startDate: {
      fieldName: 'startDate',
      fieldValue: today,
      condition: '<',
    },
    endDateInitial: {
      fieldName: 'endDate',
      fieldValue: today,
      condition: '>=',
    },
    endDate: {
      fieldName: 'endDate',
      fieldValue: ALL_2099,
      condition: '<=',
    },
    isActive: {
      fieldName: 'IsActive',
      fieldValue: 'true',
      condition: '==',
    },
  };

  const [filter, setFilter] = useState(defaultFilters);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const sort = { field: 'endDate', order: 'ASC' } as SortPayload;
  const { data, total, isLoading } = useGetList(RESOURCE_PLACEMENTS, {
    filter,
    pagination: { page, perPage },
    sort,
    meta: {
      useSmallModel: true,
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const setFilters = (newFilters) => {
    setFilter({ ...defaultFilters, ...newFilters });
  };

  const value: ListControllerResult = {
    data,
    total,
    page,
    perPage,
    filterValues: filter,
    sort,
    defaultTitle: ' ',
    error: null,
    isPending: false,
    displayedFilters: {},
    resource: '',
    selectedIds: [],
    setPage,
    setPerPage,
    setFilters,
    onSelect: () => {},
    onToggleItem: () => {},
    onUnselectItems: () => {},
    refetch: () => {},
    hideFilter: () => {},
    showFilter: () => {},
    setSort: () => {},
  };

  return (
    // @ts-ignore
    <ResourceContextProvider value={RESOURCE_CANDIDATES}>
      <ListContextProvider value={value}>
        <ListView actions={<FilterForm />} title=" ">
          <Datagrid
            rowClick={false}
            resource={RESOURCE_CANDIDATES}
            bulkActionButtons={false}
          >
            <LinkField
              source="candidateId"
              label="Candidate"
              resource={RESOURCE_CANDIDATES}
            >
              <TextField source="candidateName" sortable={false} />
            </LinkField>
            <LinkField
              source="id"
              label="Job Title"
              resource={RESOURCE_PLACEMENTS}
            >
              <TextField source="jobTitle" sortable={false} />
            </LinkField>
            <TextField source="startDate" sortable={false} />
            <TextField source="endDate" sortable={false} />
          </Datagrid>
        </ListView>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};
