import {
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_STATE,
  RESOURCE_PROJECTS,
} from '../../../provider/restProvider';
import { PostPagination } from '../../PostPagination';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import ActionsColumn from '../../ActionsColumn';
import React from 'react';

type ProjectsTabProps = {
  clientId: string | number;
};

export const ProjectsTab: React.FC<ProjectsTabProps> = ({ clientId }) => {
  const filter = {
    clientId: {
      fieldName: 'clientId',
      fieldValue: clientId.toString(),
      condition: '==',
    },
  };

  return (
    <List
      title=" "
      resource={RESOURCE_PROJECTS}
      disableSyncWithLocation={true}
      perPage={100}
      pagination={<PostPagination />}
      filter={filter}
      sort={{ field: 'name', order: 'ASC' }}
      actions={false}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <TextField source="name" sortable />
        <TextField source="description" sortable />
        <TextField source="address" sortable={false} />
        <ReferenceField
          label="State"
          source="stateId"
          reference={RESOURCE_LOOKUP_STATE}
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Country"
          source="countryId"
          reference={RESOURCE_LOOKUP_COUNTRY}
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ActionsColumn label="Actions" actionsSettings={{ show: false }} />
      </Datagrid>
    </List>
  );
};
