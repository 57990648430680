import { Admin as AdminEnterprise } from '@react-admin/ra-enterprise';
import { Resource } from '@react-admin/ra-rbac';
import './App.css';
import {
  getDataProvider,
  RESOURCE_CANDIDATE_ADDRESSES,
  RESOURCE_CANDIDATE_CLEARANCES,
  RESOURCE_CANDIDATE_CLIENT_EXPERIENCE,
  RESOURCE_CANDIDATE_DOCUMENTS,
  RESOURCE_CANDIDATE_MEMBERSHIPS,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE,
  RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE,
  RESOURCE_CANDIDATE_PLACEMENTS,
  RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_CANDIDATE_PROJECT_EXPERIENCE,
  RESOURCE_CANDIDATE_SKILLS,
  RESOURCE_CANDIDATE_SUBINDUSTRIES,
  RESOURCE_CANDIDATE_WORK_EXPERIENCE,
  RESOURCE_CANDIDATES,
  RESOURCE_CLIENT_CONTACT_CANDIDATES,
  RESOURCE_CLIENT_CONTACTS,
  RESOURCE_CLIENT_DOCUMENTS,
  RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_CLIENTS,
  RESOURCE_FAVOURITE_LIST,
  RESOURCE_HIGH_LEVEL_INDUSTRY,
  RESOURCE_JOB_RESPONSES,
  RESOURCE_JOBS,
  RESOURCE_JOBS_ADDRESSES,
  RESOURCE_LOOKUP_CITY,
  RESOURCE_LOOKUP_CLEARANCE,
  RESOURCE_LOOKUP_CLIENT_EXPERIENCE,
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_FIELDMANAGER,
  RESOURCE_LOOKUP_MEMBERSHIPS,
  RESOURCE_LOOKUP_OEM_EXPERIENCE,
  RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE,
  RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE,
  RESOURCE_LOOKUP_PROJECT_EXPERIENCE,
  RESOURCE_LOOKUP_SKILL,
  RESOURCE_LOOKUP_STATE,
  RESOURCE_LOOKUP_SUBINDUSTRY,
  RESOURCE_LOOKUP_WORK_EXPERIENCE,
  RESOURCE_MESSAGING,
  RESOURCE_PERMISSIONS_RA,
  RESOURCE_PLACEMENT_DOCUMENTS,
  RESOURCE_PLACEMENTS,
  RESOURCE_PROJECT_CONTACTS,
  RESOURCE_PROJECT_DOCUMENTS,
  RESOURCE_PROJECT_SUB_INDUSTRIES,
  RESOURCE_PROJECTS,
  RESOURCE_REPORTS,
  RESOURCE_ROLES_RA,
  RESOURCE_USERS_RA,
} from './provider/restProvider';
import { CandidateList } from './components/Candidate/CandidateList';
import { PlacementsList } from './components/Placements/PlacementsList';
import { authProvider } from './provider/authProvider';
import { CandidateEdit } from './components/Candidate/CandidateEdit';
import Login from './components/Login';
import { theme } from './theme';
import { JobsList } from './components/Job/JobsList';
import Work from '@mui/icons-material/Work';
import { JobCreate } from './components/Job/JobCreate';
import { Dashboard } from './components/Dashboard/Dashboard';
import { AppLayout } from './AppLayout';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { JobEdit } from './components/Job/JobEdit';
import { ChatMessageProvider } from './components/Messaging/ChatMessageProvider';
import BusinessIcon from '@mui/icons-material/Business';
import { ClientList } from './components/Clients/ClientList';
import { ClientEdit } from './components/Clients/ClientEdit';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import { ProjectList } from './components/Projects/ProjectList';
import { ProjectEdit } from './components/Projects/ProjectEdit';
import { ClientCreate } from './components/Clients/ClientCreate';
import { ClientContactList } from './components/ClientContacts/ClientContactList';
import { ClientContactCreate } from './components/ClientContacts/ClientContactCreate';
import { ClientContactEdit } from './components/ClientContacts/ClientContactEdit';
import { ProjectCreate } from './components/Projects/ProjectCreate';
import { CandidateCreate } from './components/Candidate/CandidateCreate';
import { FavouriteList } from './components/FavouriteList/FavouriteList';
import { FavouriteListEdit } from './components/FavouriteList/FavouriteListEdit';
import { FavouriteCreate } from './components/FavouriteList/FavouriteCreate';
import { CustomRoutes, localStorageStore } from 'react-admin';
import createMyStore from './redux/createMyStore';
import { QueryClient } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { PlacementEdit } from './components/Placements/PlacementEdit';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DATA_VALIDITY_DURATION } from './provider/constants';
import { Route } from 'react-router-dom';
import EnterEmail from './components/ForgotPassword/EnterEmail';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import EmailSent from './components/ForgotPassword/EmailSent';
import ResetConfirmation from './components/ForgotPassword/ResetConfirmation';
import { ProfileEdit, UserList, UserCreate } from './components/Users';
import Messaging from './components/Messaging/Messaging';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { ReportView } from './components/Reports/ReportView';
import { ReportList } from './components/Reports/ReportList';
import { useMemo } from 'react';
import { PermissionCreate, PermissionEdit, PermissionList, PermissionShow } from './components/Permissions';
import { RoleList } from './components/Roles/RoleList';
import { RoleEdit } from './components/Roles/RoleEdit';
import { RoleShow } from './components/Roles/RoleShow';
import { RoleCreate } from './components/Roles/RoleCreate';

export const LOCAL_STORE_VERSION_KEY = 'RaStore.version';
export const LOCAL_STORE_VERSION = '1';

function App() {
  const dataProvider = useMemo(getDataProvider, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: DATA_VALIDITY_DURATION
      },
    },
  });

  return (
    <Provider store={createMyStore()}>
      <ChatMessageProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AdminEnterprise
            title='Harmoniq HR'
            theme={theme}
            darkTheme={null}
            loginPage={Login}
            queryClient={queryClient}
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            layout={AppLayout}
            store={localStorageStore(LOCAL_STORE_VERSION)}
            disableTelemetry={true}
          >
            <CustomRoutes>
              <Route
                path='/enter-email'
                element={<EnterEmail />} />,
              <Route
                path='/reset-password'
                element={<ResetPassword />}
              />,
              <Route
                path='/email-sent'
                element={<EmailSent />}
              />,
              <Route
                path='/password-reset-confirmation'
                element={<ResetConfirmation />}
              />,
              <Route key='my-profile' path='/my-profile' element={<ProfileEdit />} />,
            </CustomRoutes>

            <Resource
              name={RESOURCE_CANDIDATES}
              list={CandidateList}
              edit={CandidateEdit}
              create={CandidateCreate}
              // show={CandidateShow}
              icon={PeopleAltIcon}
            />
            <Resource
              name={RESOURCE_JOBS}
              list={JobsList}
              icon={Work}
              edit={JobEdit}
              create={JobCreate}
            />

            <Resource
              name={RESOURCE_JOBS_ADDRESSES}
              options={{ label: 'Address' }}
            />
            <Resource
              name={RESOURCE_PLACEMENTS}
              list={PlacementsList}
              edit={PlacementEdit}
              icon={AssignmentTurnedInIcon}
            />

            <Resource name={RESOURCE_CANDIDATE_DOCUMENTS} />

            <Resource
              name={RESOURCE_CANDIDATE_ADDRESSES}
              options={{ label: 'Address' }}
            />
            <Resource name={RESOURCE_PLACEMENTS} />
            <Resource
              name={RESOURCE_JOB_RESPONSES}
            />

            <Resource
              name={RESOURCE_MESSAGING}
              list={Messaging}
              icon={ChatBubbleIcon}
            />

            <Resource
              name={RESOURCE_CLIENTS}
              list={ClientList}
              edit={ClientEdit}
              create={ClientCreate}
              icon={BusinessIcon}
            />

            <Resource name={RESOURCE_CLIENT_DOCUMENTS} />
            <Resource
              name={RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES}
              options={{ label: 'Industry' }}
            />

            <Resource
              name={RESOURCE_CLIENT_CONTACTS}
              options={{ label: 'Contact' }}
              list={ClientContactList}
              create={ClientContactCreate}
              edit={ClientContactEdit}
            />

            <Resource name={RESOURCE_CLIENT_CONTACT_CANDIDATES} />

            <Resource
              name={RESOURCE_PROJECTS}
              list={ProjectList}
              edit={ProjectEdit}
              create={ProjectCreate}
              icon={ShopTwoIcon}
            />
            <Resource
              name={RESOURCE_PROJECT_SUB_INDUSTRIES}
              options={{ label: 'Industry' }}
            />
            <Resource name={RESOURCE_PROJECT_DOCUMENTS} />
            <Resource name={RESOURCE_PROJECT_CONTACTS} />

            <Resource
              name={RESOURCE_USERS_RA}
              list={UserList}
              create={UserCreate}
            />

            <Resource name={RESOURCE_CANDIDATE_MEMBERSHIPS} />
            <Resource name={RESOURCE_CANDIDATE_CLEARANCES} />
            <Resource name={RESOURCE_CANDIDATE_SKILLS} />
            <Resource
              name={RESOURCE_CANDIDATE_SUBINDUSTRIES}
              options={{ label: 'Industry' }}
            />
            <Resource
              name={RESOURCE_CANDIDATE_PROJECT_EXPERIENCE}
              options={{ label: 'Project Experience' }}
            />
            <Resource
              name={RESOURCE_CANDIDATE_PRIMARY_WORK_EXPERIENCE}
              options={{ label: 'Primary Work Experience' }}
            />
            <Resource
              name={RESOURCE_CANDIDATE_WORK_EXPERIENCE}
              options={{ label: 'Work Experience' }}
            />
            <Resource
              name={RESOURCE_CANDIDATE_OEM_EXPERIENCE}
              options={{ label: 'Oem Experience' }}
            />
            <Resource
              name={RESOURCE_CANDIDATE_OEM_EXPERIENCE_TYPE}
              options={{ label: 'Oem Experience Type' }}
            />
            <Resource
              name={RESOURCE_CANDIDATE_CLIENT_EXPERIENCE}
              options={{ label: 'Client Experience' }}
            />

            <Resource
              name={RESOURCE_FAVOURITE_LIST}
              list={FavouriteList}
              edit={FavouriteListEdit}
              create={FavouriteCreate}
            />
            <Resource
              name={RESOURCE_REPORTS}
              list={ReportList}
              show={ReportView}
            />

            <Resource
              name={RESOURCE_PERMISSIONS_RA}
              list={PermissionList}
              create={PermissionCreate}
              edit={PermissionEdit}
              show={PermissionShow}
            />

            <Resource
              name={RESOURCE_ROLES_RA}
              list={RoleList}
              edit={RoleEdit}
              show={RoleShow}
              create={RoleCreate}
            />

            <Resource name={RESOURCE_LOOKUP_FIELDMANAGER} />
            <Resource name={RESOURCE_CANDIDATE_PLACEMENTS} />
            <Resource name={RESOURCE_LOOKUP_MEMBERSHIPS} />
            <Resource name={RESOURCE_LOOKUP_CLEARANCE} />
            <Resource name={RESOURCE_LOOKUP_SKILL} />
            <Resource name={RESOURCE_LOOKUP_SUBINDUSTRY} />
            <Resource name={RESOURCE_LOOKUP_CLIENT_EXPERIENCE} />
            <Resource name={RESOURCE_LOOKUP_PRIMARY_WORK_EXPERIENCE} />
            <Resource name={RESOURCE_LOOKUP_WORK_EXPERIENCE} />
            <Resource name={RESOURCE_LOOKUP_OEM_EXPERIENCE_TYPE} />
            <Resource name={RESOURCE_LOOKUP_OEM_EXPERIENCE} />
            <Resource name={RESOURCE_LOOKUP_PROJECT_EXPERIENCE} />
            <Resource name={RESOURCE_LOOKUP_COUNTRY} />
            <Resource name={RESOURCE_LOOKUP_STATE} />
            <Resource name={RESOURCE_LOOKUP_CITY} />
            <Resource name={RESOURCE_HIGH_LEVEL_INDUSTRY} />
            <Resource name={RESOURCE_PLACEMENT_DOCUMENTS} />
          </AdminEnterprise>
        </LocalizationProvider>
      </ChatMessageProvider>
    </Provider>
  );
}

export default App;
