import { FC } from 'react';
import { email, maxLength, required, SimpleForm, SimpleFormProps, TextInput } from 'react-admin';
import { UserFormToolbar } from './UserFormToolbar';
import { RolesReferenceArrayInput } from './RolesReferenceArrayInput';
import { ClientsReferenceArrayInput } from './ClientsReferenceArrayInput';
import { commonStyles } from '../CommonStyles';

type UserFormProps = Omit<SimpleFormProps, 'children'>;

export const UserForm: FC<UserFormProps> = (props) => {
  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={<UserFormToolbar />} {...props}>
      <TextInput
        source='title'
        label='Title'
        validate={[required(), maxLength(35)]}
        sx={commonStyles.formComponentDefaultMargins}
        fullWidth
      />
      <TextInput
        source='firstName'
        label='First Name'
        validate={[required(), maxLength(35)]}
        sx={commonStyles.formComponentDefaultMargins}
        fullWidth
      />
      <TextInput
        source='lastName'
        label='Last Name'
        validate={[required(), maxLength(35)]}
        sx={commonStyles.formComponentDefaultMargins}
        fullWidth
      />
      <TextInput
        source='email'
        label='Email'
        type='email'
        validate={[required(), email(), maxLength(35)]}
        sx={commonStyles.formComponentDefaultMargins}
        fullWidth
      />
      <TextInput
        source='phone'
        label='Phone Number'
        type='tel'
        validate={[required(), maxLength(35)]}
        sx={commonStyles.formComponentDefaultMargins}
        fullWidth
      />
      <RolesReferenceArrayInput validate={[required()]} />
      <ClientsReferenceArrayInput />
    </SimpleForm>
  );
};
