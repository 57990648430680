import { MenuProps } from 'react-admin';
import { MenuItemLink, useLogout, UserMenu, UserMenuProps } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';
import { useProfile } from '../Users/ProfileEdit';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import { clearLocalStorageListParams } from '../../provider/UtilityFunctions';
import { FC } from 'react';
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import { menuItems } from './menuItems';
import useFilteredMenuItems from './useFilteredMenuItems';
import { renderMenuItem } from './utils';

const packageJson = require('../../../package.json');

// @ts-ignore
const getVersion = () => packageJson.version;

export const MyUserMenu: FC<UserMenuProps> = (props) => {
  const { profileVersion } = useProfile();
  const logout = useLogout();

  // @ts-ignore
  const myMenu = <MenuItemLink
    to='/my-profile'
    primaryText='My Profile'
    leftIcon={<SettingsIcon />}
  />;

  // @ts-ignore
  const versionMenu = <MenuItemLink
    to=''
    primaryText={`Version ${getVersion()}`}
    onClick={() => {
    }}
  />;

  // @ts-ignore
  const clearLocalStorageListParamsMenu = <MenuItemLink
    to=''
    primaryText='Clear search settings'
    onClick={clearLocalStorageListParams}
  />;

  // // @ts-ignore
  // const clearLocalStorageColumnsMenu = <MenuItemLink
  //     to=''
  //     primaryText='Clear columns settings'
  //     onClick={clearLocalStorageColumns}
  // />;


  return (
    <UserMenu key={profileVersion} {...props}>
      {myMenu}
      {versionMenu}
      {clearLocalStorageListParamsMenu}
      {/*{clearLocalStorageColumnsMenu}*/}
      <MenuItemLink
        to=''
        primaryText={`Logout`}
        leftIcon={<ExitIcon />}
        onClick={() => logout()}
      />
    </UserMenu>
  );
};

export const AppMenu: FC<MenuProps> = (props) => {
  const filteredMenuItems = useFilteredMenuItems(menuItems);

  return (
    <MultiLevelMenu {...props}>
      {filteredMenuItems.map(renderMenuItem)}
    </MultiLevelMenu>
  );
};
