import { FC } from 'react';
import { required, TabbedForm, TabbedFormProps } from 'react-admin';
import { PermissionInput } from './PermissionInput';
import { ActionInput } from './ActionInput';
import { ResourceInput } from './ResourceInput';
import { PermissionFormToolbar } from './PermissionFormToolbar';
import { commonStyles } from '../CommonStyles';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { RoleInput } from './RoleInput';

type PermissionFormProps = Omit<TabbedFormProps, 'children'>;

export const PermissionForm: FC<PermissionFormProps> = (props) => {
  return (
    <TabbedForm
      warnWhenUnsavedChanges
      toolbar={<PermissionFormToolbar />}
      sx={commonStyles.tabbedFormBoldHeader}
      {...props}
    >
      <LazyFormTab label='Role Permission'>
        <RoleInput validate={[required()]} fullWidth />
        <PermissionInput validate={[required()]} fullWidth />
        <ActionInput validate={[required()]} fullWidth />
        <ResourceInput validate={[required()]} fullWidth />
      </LazyFormTab>
    </TabbedForm>
  );
};
