import { FC } from 'react';
import {Login} from 'react-admin';
import Logo from '../../assets/LeftLogo.png';
import { colors } from '../../theme';

const ResetConfirmation: FC = () => {
  return (
    <Login title='Harmoniq HR Portal' backgroundImage={Logo}>
      <p
        style={{
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        Your password has been reset.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2rem',
          marginBottom: '3rem',
        }}
      >
        <p
            style={{
                textAlign: 'center',
                marginTop: '0.5rem',
                fontSize: '0.9rem',
                marginBottom: '3rem',
                color: colors.text.light,
                padding: '0 2rem 0 2rem',
                width: '18rem',
            }}
        >
            Thank you - your password has been reset! you can now <a
            href='https://rev1energy.page.link/hr-app-login'>login</a>, build
            your profile, and set your availability date in the Rev1 Energy app.
        </p>
      </div>
    </Login>
  );
};

export default ResetConfirmation;
