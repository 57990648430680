import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useListContext, useStore } from 'react-admin';
import { Badge, Box, FormControlLabel, FormControlLabelProps, Switch, Tooltip } from '@mui/material';

type FilterSidebarButtonProps = Omit<FormControlLabelProps, 'control' | 'label'> & {
  sideFilter?: string;
}

export const FilterSidebarButton: FC<FilterSidebarButtonProps> = ({ sideFilter, ...props }) => {
  const [sideFilters, setSideFilters] = useStore(sideFilter, false);
  const { filterValues } = useListContext();

  const appliedFiltersCount = useMemo(() => {
    return Object.keys(filterValues).filter((key) => key !== 'q').length;
  }, [filterValues]);

  const handleSwitchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setSideFilters(e.target.checked);
    },
    [setSideFilters]
  );

  return (
    <FormControlLabel
      control={<Switch size='small' checked={sideFilters} onChange={handleSwitchChange} />}
      label={
        <Badge badgeContent={appliedFiltersCount} color='secondary'>
          <Tooltip
            title={
              appliedFiltersCount > 0
                ? `Filters Enabled (${appliedFiltersCount} applied)`
                : 'Filters Disabled'
            }
          >
            <Box sx={{ marginRight: '10px' }}>Show Filters</Box>
          </Tooltip>
        </Badge>
      }
      {...props}
    />
  );
};
