import { FC } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { actionChoices } from './constants';
import { commonStyles } from '../CommonStyles';

type ActionInputProps = Omit<SelectInputProps, 'source' | 'choices' | 'format'>;

export const ActionInput: FC<ActionInputProps> = (props) => {
  return (
    <SelectInput
      source='action'
      label='Role Action'
      choices={actionChoices}
      sx={commonStyles.formComponentDefaultMargins}
      {...props}
    />
  );
};
