import {
  BooleanInput,
  CreateButton,
  DeleteWithConfirmButton,
  Filter,
  FilterProps,
  List,
  ListProps,
  NumberField,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import ActionsColumn from '../ActionsColumn';
import { JobEdit } from './JobEdit';
import { RESOURCE_JOBS } from '../../provider/restProvider';
import ArchiveIcon from '@mui/icons-material/Archive';
import React from 'react';
import ExportButton from '../ExportButton';
import FreeTextSearchInput from '../FreeTextSearchInput';
import { commonStyles } from '../CommonStyles';
import { PostPagination } from '../PostPagination';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';

// const defaultColumns: string[] = [
//     'ID',
//     'client',
//     'jobTitle',
//     'numberOfPositions',
//     'startDate',
//     'endDate',
//     'region',
//     'updatedByName'
// ];

const rowStyle = (record) => {
  let style = {};
  if (!record) {
    return style;
  }
  return {
    ...style,
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

const JobFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props} variant={'outlined'}>
      <FreeTextSearchInput
        source="searchTerm"
        alwaysOn
        label={'Free Text Search'}
      />
      <BooleanInput source="IsArchived" label={'Archived'} alwaysOn />
    </Filter>
  );
};

const JobActions = () => (
  <TopToolbar>
    <JobFilter context={'button'} />
    <CreateButton />
    <ExportButton />
    <SelectColumnsButtonAutoSave />
  </TopToolbar>
);

const JobDetail = () => {
  const record = useRecordContext();

  return (
    <JobEdit
      syncWithLocation={false}
      resource={RESOURCE_JOBS}
      id={record?.id}
    />
  );
};

export const JobsList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Jobs"
      {...props}
      sort={{ field: 'jobTitle', order: 'ASC' }}
      filters={<JobFilter />}
      actions={<JobActions />}
      pagination={<PostPagination />}
      perPage={50}
      filterDefaultValues={{
        IsActive: {
          fieldName: 'IsActive',
          fieldValue: 'true',
          condition: '==',
        },
      }}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        expandSingle={true}
        // optimized={true}
        editComponent={<JobEdit syncWithLocation={false} />}
        rowSx={rowStyle}
        bulkActionButtons={false}
        sx={commonStyles.defaultGridStyle}
      >
        <NumberField key="id" source="id" label={'Job ID'} sortable={true} />
        <TextField
          key="clientName"
          source="clientName"
          label="Client"
          sortable={false}
        />
        <TextField key="jobTitle" source="jobTitle" sortable={true} />
        <NumberField
          key="numberOfPositions"
          source="numberOfPositions"
          label={'Number of Pos.'}
          sortable={true}
        />
        <TextField
          key="startDate"
          source="startDate"
          label={'Start'}
          sortable={true}
        />
        <TextField
          key="endDate"
          source="endDate"
          label={'End'}
          sortable={true}
        />
        <TextField key="dateAdded" source="dateAdded" sortable={true} />
        <TextField key="region" source="region" sortable={true} />
        <NumberField
          key="straightTimeBillRate"
          source="straightTimeBillRate"
          sortable={true}
        />
        <NumberField
          key="overtimeBillRate"
          source="overtimeBillRate"
          sortable={true}
        />
        <NumberField key="perDiem" source="perDiem" sortable={true} />
        <NumberField key="msp" source="msp" sortable={true} />
        <NumberField
          key="doubleTimeBillRate"
          source="doubleTimeBillRate"
          sortable={true}
        />
        <NumberField
          key="straightTimePayRate"
          source="straightTimePayRate"
          sortable={true}
        />
        <NumberField
          key="overtimePayRate"
          source="overtimePayRate"
          sortable={true}
        />
        <NumberField
          key="doubleTimePayRate"
          source="doubleTimePayRate"
          sortable={true}
        />
        <TextField
          key="assignedToName"
          source="assignedToName"
          label="Assigned To"
          sortable={false}
        />
        <TextField
          key="createdByName"
          source="createdByName"
          label="Created By"
          sortable={false}
        />
        <TextField
          key="updatedByName"
          source="updatedByName"
          label="Updated By"
          sortable={false}
        />

        <ActionsColumn
          label={'Actions'}
          actionsSettings={{ show: false }}
          customDeleteButton={
            <DeleteWithConfirmButton
              label=""
              confirmTitle={'Archive Job'}
              confirmContent={'Are you sure you want to archive this item?'}
              icon={<ArchiveIcon />}
            />
          }
        />
      </DatagridConfigurableRbac>
    </List>
  );
};
