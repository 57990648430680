import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { RESOURCE_CLIENT_DOCUMENTS } from '../../../provider/restProvider';
import { commonStyles } from '../../CommonStyles';
import ActionsColumn from '../../ActionsColumn';
import DownloadButton from '../../Candidate/DownloadButton';
import { UploadDocument } from '../../Candidate/UploadDocument';
import React from 'react';

type DocumentsTabProps = {
  clientId: string | number;
};

export const DocumentsTab: React.FC<DocumentsTabProps> = ({ clientId }) => {
  return (
    <>
      <ReferenceManyField
        reference={RESOURCE_CLIENT_DOCUMENTS}
        target="documents"
        label=""
        perPage={100}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid
          bulkActionButtons={false}
          sx={commonStyles.subGridDefaultStyle}
          rowClick={false}
        >
          <TextField
            source="documentType"
            label="Type"
            sx={{ textTransform: 'capitalize' }}
          />
          <TextField source="name" label="File Name" />
          <DateField source="creationDate" />
          <ActionsColumn
            actionsSettings={{ show: false, edit: false }}
            label="Actions"
          >
            <DownloadButton source="url" label="" />
          </ActionsColumn>
        </Datagrid>
      </ReferenceManyField>
      <UploadDocument
        idField="clientId"
        idValue={clientId}
        resource={RESOURCE_CLIENT_DOCUMENTS}
        documentTypes={[{ id: 'general', name: 'General' }]}
      />
    </>
  );
};
