import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import Message from '@mui/icons-material/Message';
import { Button, Identifier, useNotify, useUnselectAll } from 'react-admin';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import { useChatMessage } from '../../components/Messaging/ChatMessageProvider';
import { isEmpty } from '../../provider/UtilityFunctions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { colors, shadows } from '../../theme';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: shadows.main,
  p: 4,

  '& h2': {
    color: colors.text.main,
    fontSize: '1rem',
    marginBottom: '1rem',
  },

  '& div': {
    marginBottom: '0.5rem',
  },
};

export type SendNotificationButtonProps = {
  selectedIds: Identifier[];
  jobId?: string;
  resource?: string;
};

const SendNotificationButton: FC<SendNotificationButtonProps> = ({
  selectedIds = [],
  jobId,
  resource
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setTextMessage('');
  };
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const { sendMessage } = useChatMessage();
  const [textMessage, setTextMessage] = useState('');

  const notifyCandidatesByChat = () => {
    const notifyCandidates = selectedIds.map((selectedCandidateId) => {
      return sendMessage(
        parseInt(selectedCandidateId as string),
        jobId ? `${textMessage} candidate/joblink/${jobId}` : textMessage
      );
    });

    return notifyCandidates;
  };
  return (
    <>
      <Button
        label='Notification'
        onClick={() => setOpen(true)}
        style={{ marginRight: '1rem' }}
      >
        <Message />
      </Button>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Enter Message to notify candidate
            </Typography>
            <TextField
              id='outlined-basic'
              label='Enter Message'
              variant='outlined'
              fullWidth
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
            />

            <Button
              disabled={isEmpty(textMessage)}
              onClick={() => {
                notifyCandidatesByChat();
                setTimeout(() => {
                  notify('Notification Sent', { type: 'info', undoable: false });
                  unselectAll();
                  handleClose();
                }, 500);
              }}
              label='Notify Candidate'
            >
              <NotificationsActive />
            </Button>
          </Box>
        </Modal>
      </div>
    </>
  );
};

SendNotificationButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default SendNotificationButton;
