import { FC, useCallback, useMemo } from 'react';
import { Button, Filter, useListContext } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { ResourceInput } from './ResourceInput';
import { ActionInput } from './ActionInput';
import { PermissionInput } from './PermissionInput';
import { RoleInput } from './RoleInput';

export const PermissionSidebarFilters: FC = () => {
  const { filterValues, setFilters } = useListContext();

  const handleClearFilters = useCallback(() => {
    setFilters({ q: filterValues.q });
  }, [filterValues, setFilters]);

  const isResetFiltersButtonDisabled = useMemo(() => (
    Object.keys(filterValues).filter((filter) => filter !== 'q').length === 0
  ), [filterValues]);

  return (
    <Card sx={permissionSidebarFiltersStyles.sideFiltersCard}>
      <CardContent sx={permissionSidebarFiltersStyles.sideFiltersCardContainer}>
        <Filter>
          <RoleInput fullWidth alwaysOn />
          <PermissionInput fullWidth alwaysOn />
          <ResourceInput fullWidth alwaysOn />
          <ActionInput fullWidth alwaysOn />
        </Filter>
        <Button
          variant='contained'
          label='Clear filters'
          disabled={isResetFiltersButtonDisabled}
          fullWidth
          onClick={handleClearFilters}
        />
      </CardContent>
    </Card>
  );
};

const permissionSidebarFiltersStyles = {
  sideFiltersCard: {
    order: -1,
    mr: 1,
    mt: 1.25,
    mb: 6.5,
    width: 255,
    minWidth: 255,
  },
  sideFiltersCardContainer: {
    '& form.RaFilter-form': {
      display: 'block',
      '& > div': { display: 'block' },
    },
  },
};
