import { defaultTheme, RaThemeOptions } from 'react-admin';
import { colors } from './colors';
import { createTheme } from '@mui/material/styles';
import { light as muiPaletteLight } from '@mui/material/styles/createPalette';
import { enUS } from '@mui/material/locale';
import { typography } from './typography';

const customTheme: RaThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      paper: colors.surface.white,
      default: colors.surface.white,
    },
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.primary.main,
    },
    text: {
      primary: colors.text.main,
      secondary: colors.text.light,
    },
  },
  typography,
  sidebar: {
    width: 240,
    closedWidth: 50,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    MuiLink: {
      defaultProps: {
        color: colors.primary.light,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& a': {
            color: colors.primary.light,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.RaMenuItem-nestedList': {
            paddingLeft: 0,
            '& .MuiMenuItem-root, li': {
              paddingLeft: '40px',
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .RaMenuItemCategory-link, .RaMenuItem-link .MuiListItemIcon-root': {
            color: muiPaletteLight.action.active
          }
        },
      },
    }
  },
};

export const theme = createTheme({ ...defaultTheme, ...customTheme }, enUS);
