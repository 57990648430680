import { FC } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  ReferenceArrayInput,
} from 'react-admin';
import { RESOURCE_ROLES_RA } from '../../provider/restProvider';
import { commonStyles } from '../CommonStyles';

type RolesReferenceArrayInputProps = Omit<
  AutocompleteArrayInputProps,
  'children' | 'source' | 'optionText' | 'optionValue'
>;

export const RolesReferenceArrayInput: FC<RolesReferenceArrayInputProps> = (props) => {
  return (
    <ReferenceArrayInput source='roles' reference={RESOURCE_ROLES_RA}>
      <AutocompleteArrayInput
        label='Roles'
        sx={commonStyles.formComponentDefaultMargins}
        fullWidth
        {...props}
      />
    </ReferenceArrayInput>
  );
};
