import { FC } from 'react';
import {
  List,
  ListProps,
  ReferenceField,
  TextField,
  useStore,
} from 'react-admin';
import { RESOURCE_PERMISSIONS_RA, RESOURCE_ROLES_RA } from '../../provider/restProvider';
import { ResourceField } from './ResourceField';
import ActionsColumn from '../ActionsColumn';
import { PermissionFilter } from './PermissionFilter';
import { PermissionListActions } from './PermissionListActions';
import { PermissionEdit } from './PermissionEdit';
import { PostPagination } from '../PostPagination';
import { commonStyles } from '../CommonStyles';
import { PermissionSidebarFilters } from './FilterList';
import { DeletePermissionButton } from './DeletePermissionButton';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';
import { PermissionShow } from './PermissionShow';

export const PermissionList: FC<ListProps> = (props) => {
  const sideFilter = getSideFiltersKey(RESOURCE_PERMISSIONS_RA);
  const [showSideFilter] = useStore(sideFilter, false);

  return (
    <List
      title='Role Permissions'
      actions={<PermissionListActions sideFilter={sideFilter} />}
      filters={<PermissionFilter />}
      pagination={<PostPagination />}
      sort={{ field: 'id', order: 'ASC' }}
      perPage={50}
      sx={commonStyles.raFilterForm}
      aside={showSideFilter && <PermissionSidebarFilters />}
      {...props}
    >
      <DatagridConfigurableRbac
        bulkActionButtons={false}
        editComponent={<PermissionEdit syncWithLocation={false} />}
        showComponent={<PermissionShow {...props} />}
        rowClick={false}
        expandSingle
      >
        <TextField source='id' label='ID' />
        <ReferenceField source='hrRoleId' reference={RESOURCE_ROLES_RA} label='Role' link='show' />
        <TextField source='permissionType' label='Permission Type' />
        <ResourceField source='resource' label='Resource' />
        <TextField source='action' label='Role Action' />
        <ActionsColumn
          label='Actions'
          customDeleteButton={<DeletePermissionButton />}
        />
      </DatagridConfigurableRbac>
    </List>
  );
};

const getSideFiltersKey = (resource: string): string => `${resource}.sideFilters`;
