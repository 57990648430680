import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useSound from 'use-sound';
import msgReceivedSound from '../../assets/message-received.mp3';
import { RESOURCE_MESSAGING } from "../../provider/restProvider";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { RootState } from "../../redux/reducers/initialState";
import { useChatMessage } from "./ChatMessageProvider";
import Badge from '@mui/material/Badge';

const MessagingMenuBadge = () => {
    const [play, {stop}] = useSound(msgReceivedSound, {volume: 0.4});
    const msgCounter = useSelector((state: RootState) => state.chatkit.receivedMessageCounter);
    const totalUnReadMessages = useSelector((state: RootState) => state.chatkit.totalUnReadMessages);
    const chatContext = useChatMessage();

    useEffect(() => {
        if (!chatContext.isChatConnected()) {
            chatContext.connect();
        }
        return () => chatContext.disconnect();
    }, []);


    useEffect(() => {
        if (msgCounter) {
            stop();
            play();
        }
    }, [msgCounter]);

    // @ts-ignore
    return (
      <Badge
        key={RESOURCE_MESSAGING}
        badgeContent={totalUnReadMessages}
        color="secondary"
      >
          <ChatBubbleIcon fontSize={'small'}/>
      </Badge>
    );
};


export default MessagingMenuBadge;
