import { FC, useEffect, useState } from 'react';
import { Button, Login } from 'react-admin';
import Logo from '../../assets/LeftLogo.png';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import api from '../../provider/api';
import { authProvider } from '../../provider/authProvider';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../theme';

const classes = {
  inlineBlock: {
    display: 'inline-block !important',
    marginRight: '0.5rem',
    verticalAlign: 'middle',
  },
  '& .RaFormInput-input': {
    display: 'inline-block !important',
  },
  raInput2: {
    marginRight: '0.5rem',
  },
  formContainer: {
    '& .ra-input': {
      display: 'inline-block !important',
    },
    '& .ra-input-description': {
      width: '80% !important',
    },
    '& .ra-input-undefined': {
      marginTop: '-1.2rem',
      width: '100%',
    },
  }
};

const EnterEmail: FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (email === '') {
      setEmailError('');
    }
  }, [email]);

  const forgottenPassword = async () => {
    try {
      const response = await api.auth.forgotPassword({
        email,
      });
      const { data } = response;
      if (data) {
        await authProvider.logout('');
        setEmailError('');
        return navigate('/email-sent');
      }
    } catch (err) {
      setEmailError('Please enter valid email address');
    }
    return undefined;
  };

  return (
    <Login title='Harmoniq HR Portal' backgroundImage={Logo}>
      <p
        style={{
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        Enter email to reset password
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2rem',
          marginBottom: '3rem',
        }}
      >
        <TextField
          style={{ width: '256px' }}
          label='Email'
          sx={classes.inlineBlock}
          value={email}
          variant='outlined'
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError !== '' && (
          <p
            style={{
              textAlign: 'center',
              fontSize: '0.8rem',
              color: colors.indicator.red,
            }}
          >
            {emailError}
          </p>
        )}
        <p
          style={{
            textAlign: 'center',
            marginTop: '0.5rem',
            fontSize: '0.8rem',
            marginBottom: '3rem',
            color: colors.text.light,
            padding: '0 2rem 0 2rem',
          }}
        >
          We will email you a password reset link.
        </p>
        <Button
          label='Email Link'
          onClick={forgottenPassword}
          disabled={email === ''}
          style={{
            background: colors.surface.grey,
            padding: '1rem',
          }}
        >
          <SendIcon />
        </Button>
      </div>
    </Login>
  );
};

export default EnterEmail;
