import { FC } from 'react';
import { AutocompleteInput, AutocompleteInputProps, useResourceDefinitions } from 'react-admin';
import { commonStyles } from '../CommonStyles';
import { convertResourceKeyToName } from './helpers';

type ResourceInputProps = Omit<AutocompleteInputProps, 'source' | 'choices'>;

export const ResourceInput: FC<ResourceInputProps> = (props) => {
  const resourceChoices = useResourceChoices();

  return (
    <AutocompleteInput
      source='resource'
      choices={resourceChoices}
      label='Resource'
      sx={commonStyles.formComponentDefaultMargins}
      {...props}
    />
  );
};

const useResourceChoices = () => {
  const resources = useResourceDefinitions();

  const choices = Object.keys(resources).map((key) => {
    return { id: key, name: convertResourceKeyToName(key) };
  });

  choices.unshift({ id: '*', name: 'All' });

  return choices;
};
