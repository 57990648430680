import React, { ReactElement } from 'react';
import { Create, CreateProps, maxLength, required, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import Box from '@mui/material/Box';
import { commonStyles } from '../CommonStyles';

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      label="Save"
      variant="text"
      transform={(data) => {
        return {
          ...data,
          isActive: true,
        };
      }}
    />
  </Toolbar>
);

export const RoleCreate: React.FC<CreateProps> = (props): ReactElement => (
  <Create {...props} actions={false} >
    <SimpleForm warnWhenUnsavedChanges
                toolbar={
                  <EditToolbar/>
                }
                style={{width: "100%"}}>
      <Box>
        <TextInput
          source="name"
          label="Role"
          validate={[required(), maxLength(35)]}
          sx={commonStyles.formComponentDefaultMargins}
        />
      </Box>
    </SimpleForm>
  </Create>
);
