import React, { ReactElement } from 'react';
import {
  Labeled,
  Show,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import { ShowPropsWithLocation } from '../types';
import Box from '@mui/material/Box';
import { renderAuditFields } from '../AuditFields/renderAuditFields';

export const RoleShow: React.FC<ShowPropsWithLocation> = ({
                                                            syncWithLocation,
                                                            ...props
                                                          }): ReactElement => {
  return (
    <Show
      {...props}
      title={syncWithLocation === undefined ? "" : " "}
      actions={false}
    >
      <TabbedShowLayout
        syncWithLocation={syncWithLocation}
      >
        <TabbedShowLayout.Tab label="Role">
          <Labeled label="Role">
            <TextField source="name" />
          </Labeled>
          {/*<Box>{renderAuditFields()}</Box>*/}
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
}
