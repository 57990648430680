import { FC } from 'react';
import { CreateButton, SelectColumnsButton, ToolbarProps, TopToolbar } from 'react-admin';
import { FilterSidebarButton } from './FilterSidebarButton';

type PermissionListActionsProps = Omit<ToolbarProps, 'children'> & {
  sideFilter?: string;
};

export const PermissionListActions: FC<PermissionListActionsProps> = ({
  sideFilter,
  ...props
}) => {
  return (
    <TopToolbar {...props}>
      <FilterSidebarButton sideFilter={sideFilter} />
      <SelectColumnsButton />
      <CreateButton />
    </TopToolbar>
  );
};
