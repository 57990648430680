import { blueGrey, green, grey, purple, yellow } from '@mui/material/colors';

export const colors = {
  primary: {
    light: '#004ec2',
    main: '#0049b7',
  },
  text: {
    light: blueGrey[600],
    main: blueGrey[900],
  },
  surface: {
    white: '#fff',
    grey: '#51666e4a',
  },
  indicator: {
    red: '#f75990',
    green: green[500],
    yellow: yellow[500],
    orange: '#ff7c26',
    purple: purple[700],
    grey: grey[500],
    black: '#000',
    peach: '#ffcfb0',
  },
};
