import axios from "axios";
import {getToken} from "./authProvider";
import {Identifier} from "ra-core/src/types";
import { fetchUtils, Options } from "react-admin";

type environmentType = 'local' | 'dev' | 'prod';
let environment: environmentType = setEnvironment();

function setEnvironment() {
  if (document.URL.includes("//hr.")) {
    return 'prod';
  } else if (document.URL.includes("//localhost") || document.URL.includes("//127.")) {
    return 'local';
  } else {
    return 'dev';
  }
}


function getAPIURL() {
  switch (environment) {
    case "local":
      return "https://localhost:44356/api";
      break;
    case "dev":
      return 'https://gwj0qq4cha.execute-api.eu-west-1.amazonaws.com/api'
      break;
    case "prod":
      return "https://ewpz5ffbu4.execute-api.us-east-2.amazonaws.com/api";
      break;
    default:
      throw `Unknown environment:${environment}`
  }
}

function getSecurityAPIURL() {
  switch (environment) {
    case "local":
      return "https://localhost:44382/api";
      break;
    case "dev":
      return 'https://gwj0qq4cha.execute-api.eu-west-1.amazonaws.com/security';
      break;
    case "prod":
      return "https://ewpz5ffbu4.execute-api.us-east-2.amazonaws.com/security";
      break;
    default:
      throw `Unknown environment:${environment}`
  }
}

function getChatAPIURL() {
  switch (environment) {
    case "local":
      return "http://localhost:53422";
      break;
    case "dev":
      return 'https://hr-chat-dev.rev1energy.com';
      break;
    case "prod":
      return "https://hr-chat.rev1energy.com";
      break;
    default:
      throw `Unknown environment:${environment}`
  }
}


export const REACT_APP_REVONE_API_URL = getAPIURL();
const REACT_APP_REVONE_API_LOGIN_URL = getSecurityAPIURL();
const REACT_APP_REVONE_CHAT_URL = getChatAPIURL();

export interface GetListV2Filter {
  fieldName: string;
  fieldValue: string;
  condition: string;
}

export interface GetListV2Params {
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  sortDirection?: string;
  searchTerm?: string;
  fieldFilters?: GetListV2Filter[];
  sideFilters?: [];
  ids?: Identifier[];
  includeFields?: string[];
  useSmallModel?: boolean
}

let authToken = getToken();
let token = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  },
};

export function setToken() {
  authToken = getToken();
  token = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };
}

const bearerToken = (tkn) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tkn}`,
    },
  };
  return headers;
};

const headers = {
  headers: { "Content-Type": "application/json" },
};

export const httpClient = (url: string, options: Options) => {
  if (!options.headers) {
    options.headers = new Headers(options.headers || new Headers(token.headers));
  }

  options.user = {
    authenticated: true,
    token: `Bearer ${authToken}`,
  };

  return fetchUtils.fetchJson(url, options);
};

const api = {
  auth: {
    login: (data) =>
      axios.post(
        `${REACT_APP_REVONE_API_LOGIN_URL}/HrUsers/authenticate`,
        data,
        headers
      ),
    forgotPassword: (email) =>
      axios.post(
        `${REACT_APP_REVONE_API_LOGIN_URL}/HrUsers/forgot-password`,
        email,
        headers
      ),
    resetPassword: (data) =>
      axios.post(
        `${REACT_APP_REVONE_API_LOGIN_URL}/HrUsers/reset-password`,
        data,
        headers
      ),
    getCurrentUserDetails: (tkn) => {
      return axios.get(`${REACT_APP_REVONE_API_URL}/users`, bearerToken(tkn));
    },
    forgotPasswordCandidate: (userId) => {
      return axios.post(`${REACT_APP_REVONE_API_LOGIN_URL}/HrUsers/forgot-password-candidate`, {userId}, token);
    },
    registerCandidate: (data) => {
      return axios.post(`${REACT_APP_REVONE_API_LOGIN_URL}/HrUsers/register-candidate-fromapp`, data, token);
    },
    unRegisterCandidate: (id) => {
      return axios.delete(`${REACT_APP_REVONE_API_LOGIN_URL}/HrUsers/${id}`, token);
    },
    updateUserSettings: (userSettings) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/users/settings`, userSettings, token);
    },

  },
  candidates: {
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/candidates`, params, token);
    },
    create: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/candidates`, data, token),
    update: (payload) =>
      axios.put(
        `${REACT_APP_REVONE_API_URL}/candidates/${payload.id}`,
        payload.data,
        token
      ),
    delete: (id) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/candidates/${id}`, token),
    getCandidate: (id) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/candidates/${id}`, token),
    getComments: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/comments/candidate/${candidateId}`,
        token
      ),
    deleteComment: (commentId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/comments/candidate/${commentId}`,
        token
      ),
    createComment: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/comments/candidate`, data, token),
    getCandidateDocuments: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/documents`,
        token
      ),
    getAddresses: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/addresses`,
        token
      ),
    createAddress: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/addresses`,
        data,
        token
      ),
    updateAddress: (candidateId, data) =>
      axios.put(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/addresses`,
        data,
        token
      ),
    deleteAddress: (candidateId, addressId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/addresses/${addressId}`,
        token
      ),
    setDefaultAddress: (candidateId, addressId) =>
        axios.get(
            `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/addresses/${addressId}/setdefault`,
            token
        ),
    getMemberships: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/affiliationmemberships`,
        token
      ),
    deleteMembership: (candidateId, membershipId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/affiliationmemberships/${membershipId}`,
        token
      ),
    createMembership: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/affiliationmemberships`,
        data,
        token
      ),
    getClearances: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/clearances`,
        token
      ),
    deleteClearance: (candidateId, clearanceId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/clearances/${clearanceId}`,
        token
      ),
    createClearance: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/clearances`,
        data,
        token
      ),
    getSkills: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/skills`,
        token
      ),
    deleteSkill: (candidateId, skillId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/skills/${skillId}`,
        token
      ),
    createSkill: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/skills`,
        data,
        token
      ),
    getSubindustries: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/subindustries`,
        token
      ),
    deleteSubindustry: (candidateId, subIndustryId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/subindustries/${subIndustryId}`,
        token
      ),
    createSubindustry: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/subindustries`,
        data,
        token
      ),
    getProjectExperiences: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateprimaryprojectphases`,
        token
      ),
    deleteProjectExperience: (candidateId, projectId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateprimaryprojectphases/${projectId}`,
        token
      ),
    createProjectExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateprimaryprojectphases`,
        data,
        token
      ),
    getClientExperiences: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/clientexperiences`,
        token
      ),
    deleteClientExperience: (candidateId, clientId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/clientexperiences/${clientId}`,
        token
      ),
    createClientExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/clientexperiences`,
        data,
        token
      ),
    getOemExperience: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/oemexperience`,
        token
      ),
    deleteOemExperience: (candidateId, oemId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/oemexperience/${oemId}`,
        token
      ),
    createOemExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/oemexperience`,
        data,
        token
      ),
    getOemExperienceType: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/oemexperiencetype`,
        token
      ),
    deleteOemExperienceType: (candidateId, oemId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/oemexperiencetype/${oemId}`,
        token
      ),
    createOemExperienceType: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/oemexperiencetype`,
        data,
        token
      ),
    getWorkExperiences: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateworkexperiencelevel`,
        token
      ),
    deleteWorkExperience: (candidateId, workId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateworkexperiencelevel/${workId}`,
        token
      ),
    createWorkExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateworkexperiencelevel`,
        data,
        token
      ),
    getPrimaryWorkExperiences: (candidateId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateprimaryworkscopeexperience`,
        token
      ),
    deletePrimaryWorkExperience: (candidateId, workId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateprimaryworkscopeexperience/${workId}`,
        token
      ),
    createPrimaryWorkExperience: (candidateId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/candidates/${candidateId}/candidateprimaryworkscopeexperience`,
        data,
        token
      ),
  },
  jobs: {
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/jobs`, params, token);
    },
    create: (data) =>
    axios.post(`${REACT_APP_REVONE_API_URL}/jobs`, data, token),
    getComments: (jobId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/comments/job/${jobId}`, token),
    deleteComment: (commentId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/comments/job/${commentId}`,
        token
      ),
    createComment: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/comments/job`, data, token),
    getAddresses: (jobId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/jobs/${jobId}/addresses`, token),
    createAddress: (jobId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v2/jobs/${jobId}/addresses`,
        data,
        token
      ),
    updateAddress: (jobId, data) =>
      axios.put(
        `${REACT_APP_REVONE_API_URL}/v2/jobs/${jobId}/addresses`,
        data,
        token
      ),
    deleteAddress: (jobId, addressId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/jobs/${jobId}/addresses/${addressId}`,
        token
      ),
    search: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/jobs/search`, data, token),
    searchDates: (payload) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/jobs?availabilityStart=${payload.formatStartOfDate}&availabilityEnd=${payload.formatEndOfDate}`,
        token
      ),
    update: (payload) =>
      axios.put(
        `${REACT_APP_REVONE_API_URL}/jobs/${payload.id}`,
        payload.data,
        token
      ),
    delete: (id) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/jobs/${id}`, token),
    getJob: (id) => axios.get(`${REACT_APP_REVONE_API_URL}/jobs/${id}`, token),
    clientNameGetData: () =>
      axios.get(`${REACT_APP_REVONE_API_URL}/lookup/client`, token),
  },
  jobresponses: {
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/jobresponses`, params, token);
    },
    getData: () => axios.get(`${REACT_APP_REVONE_API_URL}/jobresponses`, token),
    getJobCandidates: (jobId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/jobs/${jobId}/jobresponses`,
        token
      ),
    addProspects: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/jobresponses`, data, token),
    create: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/jobresponses`, data, token),
    update: (payload) =>
      axios.put(
        `${REACT_APP_REVONE_API_URL}/jobresponses/${payload.id}`,
        payload.data,
        token
      ),
    delete: (id) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/jobresponses/${id}`, token),
  },
  placements: {
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/placements`, params, token);
    },
    getComments: (placementId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/comments/placement/${placementId}`,
        token
      ),
    getDocuments: (placementId) =>
        axios.get(
          `${REACT_APP_REVONE_API_URL}/placements/${placementId}/documents`,
          token,
        ),
    deleteComment: (placementId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/comments/placement/${placementId}`,
        token
      ),
    createComment: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/comments/placement`, data, token),

    create: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/placements`, data, token),
    update: (payload) =>
      axios.put(
          `${REACT_APP_REVONE_API_URL}/placements/${payload.id}`,
          payload,
          token
      ),
    delete: (id) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/placements/${id}`, token),
    getPlacement:(id) => axios.get(`${REACT_APP_REVONE_API_URL}/placement/${id}`, token)
  },
  lookup: {
    getData: (lookupType, parentId = undefined, parentName = undefined, keyword = undefined) =>{
      if (parentId){
        return axios.get(`${REACT_APP_REVONE_API_URL}/${lookupType}?parentId=${parentId}`, token)
      }else if (parentName){
        return axios.get(`${REACT_APP_REVONE_API_URL}/${lookupType}?parentName=${parentName}`, token)
      }else if (keyword){
        return axios.get(`${REACT_APP_REVONE_API_URL}/${lookupType}?keyword=${keyword}`, token)
      }else{
        return axios.get(`${REACT_APP_REVONE_API_URL}/${lookupType}`, token)
      }
    },
    getAll: (lookupType) => {
      return axios.get(`${REACT_APP_REVONE_API_URL}/lookup/${lookupType}`, token)
    }
  },
  chat: {
    getChatRooms: (archived = false) => axios.get(`${REACT_APP_REVONE_API_URL}/chat?archived=${archived}`, token),
    getChatRoomMessages: (roomId) =>
        axios.get(`${REACT_APP_REVONE_API_URL}/chat/${roomId}`, token),
    getFieldManagerProfile: (fieldManagerId) =>
        axios.get(
            `${REACT_APP_REVONE_API_URL}/lookup/fieldmanager/${fieldManagerId}`,
            token
        ),
    getChatURL: () =>
        `${REACT_APP_REVONE_CHAT_URL}/harmoniqhub?access_token=${authToken}`,
    resetUnReadMessages: (roomId) =>
        axios.get(
            `${REACT_APP_REVONE_API_URL}/chat/${roomId}/resetunread`,
            token
        ),
    createChatRoom: (candidateId) =>
        axios.post(
            `${REACT_APP_REVONE_API_URL}/candidate/${candidateId}/chat`,
            undefined,
            token
        ),
    archiveChatRoom: (roomId) => axios.put(`${REACT_APP_REVONE_API_URL}/v2/chat/${roomId}/archive`, undefined, token),
    unarchiveChatRoom: (roomId) => axios.put(`${REACT_APP_REVONE_API_URL}/v2/chat/${roomId}/unarchive`, undefined, token),
  },
  documents: {
    getUrl: (documentId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/documents/${documentId}/url`,
        token
      ),
      getCandidatePreview: (placementId) =>
        axios.get(
          `${REACT_APP_REVONE_API_URL}/previewcandidateletter?placementId=${placementId}`, {...token, responseType: 'arraybuffer'}
        ),
      getEmployerPreview: (placementId) =>
        axios.get(
          `${REACT_APP_REVONE_API_URL}/previewemployerletter?placementId=${placementId}`, {...token, responseType: 'arraybuffer'}
        ),
      sendCandidateLetter: (placementId) =>
        axios.post(
          `${REACT_APP_REVONE_API_URL}/sendemployeeletter?placementId=${placementId}`, {placementId}, token
        ),
      sendEmployerLetter: (placementId) =>
        axios.post(
          `${REACT_APP_REVONE_API_URL}/sendexhibitletter?placementId=${placementId}`, {placementId}, token
        ),
      upload: (formData) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/documents/`, formData, token),
    delete: (documentId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/documents/${documentId}`,
        token
        ),
        parseCv: (document) =>
        axios.post(`${REACT_APP_REVONE_API_URL}/documents/${document.id}/parsecv`, document,  token),
        mapSkills: (candidateId, skills) =>
        axios.post(`${REACT_APP_REVONE_API_URL}/documents/${candidateId}/actionSkills`, skills,  token),
      },
  clients: {
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/clientspaged`, params, token);
    },
    update: (payload) => {
      return axios.put(`${REACT_APP_REVONE_API_URL}/v2/clients/${payload.id}`, payload.data, token);
    },
    create: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v2/clients`, data, token),
    getComments: (clientId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/comments/client/${clientId}`,
        token
      ),
    deleteComment: (clientId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/comments/client/${clientId}`,
        token
    ),
    createComment: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/comments/client`, data, token),
    getDocuments: (clientId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/clients/${clientId}/documents`,
        token
      ),
    getHighLevelIndustries: (clientId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/v2/clients/${clientId}/highlevelindustries`,
        token
      ),
    createHighLevelIndustry: (clientId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/v2/clients/${clientId}/highlevelindustries`,
        data,
        token
      ),
    deleteHighLevelIndustry: (clientId, industryId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/v2/clients/${clientId}/highlevelindustries/${industryId}`,
        token
      ),
    getClient: (clientId) => {
      return axios.get(`${REACT_APP_REVONE_API_URL}/v2/clients/${clientId}`, token);
    },
    delete: (clientId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v2/clients/${clientId}`, token),
  },
  clientContacts:{
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/clientcontactspaged`, params, token);
    },
    getContact: (id) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts/${id}`, token),
    update: (payload) => {
      return axios.put(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts/${payload.id}`, payload.data, token);
    },
    create: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts`, data, token),
    delete: (id) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts/${id}`, token),
    favoriteCandidates : (id, candidateIds) =>
       axios.post(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts/${id}/candidates`, {candidateIds}, token),
    getFavoriteCandidates : (id) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts/${id}/candidates`, token),
    removeFavoriteCandidate : (clientContactId, candidateId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts/${clientContactId}/candidates/${candidateId}`, token),
  },
  projects: {
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/projectspaged`, params, token);
    },
    getProject: (projectId) => {
      return axios.get(`${REACT_APP_REVONE_API_URL}/v2/projects/${projectId}`, token);
    },
    delete: (projectId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v2/projects/${projectId}`, token),
    update: (payload) => {
      return axios.put(`${REACT_APP_REVONE_API_URL}/v2/projects/${payload.id}`, payload.data, token);
    },
    getSubIndustries: (projectId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/projects/${projectId}/subindustries`,
        token
      ),
    createSubIndustry: (projectId, data) =>
      axios.post(
        `${REACT_APP_REVONE_API_URL}/projects/${projectId}/subindustries`,
        data,
        token
      ),
    deleteSubIndustry: (projectId, industryId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/projects/${projectId}/subindustries/${industryId}`,
        token
      ),
    getDocuments: (projectId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/projects/${projectId}/documents`,
        token
      ),
    create: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v2/projects`, data, token),
    getComments: (projectId) =>
      axios.get(
        `${REACT_APP_REVONE_API_URL}/comments/project/${projectId}`,
        token
      ),
    createComment: (data) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/comments/project`, data, token),
    deleteComment: (projectId) =>
      axios.delete(
        `${REACT_APP_REVONE_API_URL}/comments/project/${projectId}`,
        token
      ),
    getContacts: (projectId) =>
      axios.get(`${REACT_APP_REVONE_API_URL}/v2/project/${projectId}/clientcontacts`, token),
    assignContacts: (projectId, ids: Identifier[]) =>
      axios.post(`${REACT_APP_REVONE_API_URL}/v2/project/${projectId}/clientcontacts`, {clientContactIds: ids}, token),
    removeContact: (clientContactId, projectId) =>
      axios.delete(`${REACT_APP_REVONE_API_URL}/v2/clientcontacts/${clientContactId}/projects/${projectId}`, token),
  },
  users: {
    getList: (params: GetListV2Params) => {
      return axios.post(`${REACT_APP_REVONE_API_URL}/v2/users`, params, token);
    },
    getUser: (id) => {
      return axios.get(`${REACT_APP_REVONE_API_URL}/v2/users/${id}`, token);
    },
    deactivateUser: (id) =>
        axios.delete(`${REACT_APP_REVONE_API_URL}/fieldmanagers/${id}/deactivate`, token),
    createUser: (data) =>
        axios.post(`${REACT_APP_REVONE_API_URL}/users`, data, token),
    uploadImage: (fieldManagerId, formData) =>
        axios.post(`${REACT_APP_REVONE_API_URL}/fieldmanagers/${fieldManagerId}/images`, formData, token),
    updateFieldManagerPhone: (fieldManagerId, phoneNumber) =>
        axios.put(`${REACT_APP_REVONE_API_URL}/users/fieldmanager/${fieldManagerId}/phone`, phoneNumber, token),
  },
  favourites : {
    getList: () => axios.get(`${REACT_APP_REVONE_API_URL}/fieldmanagers/favourites`, token),
    getOne: (id) => axios.get(`${REACT_APP_REVONE_API_URL}/fieldmanagers/favourites/${id}`, token),
    update: (payload) => axios.put(`${REACT_APP_REVONE_API_URL}/fieldmanagers/favourites/${payload.id}`, payload.data, token),
    create: (data) => axios.post(`${REACT_APP_REVONE_API_URL}/fieldmanagers/favourites`, data, token),
    delete:(id) => axios.delete(`${REACT_APP_REVONE_API_URL}/fieldmanagers/favourites/${id}`, token),
  },
  reports : {
    getList: () => axios.get(`${REACT_APP_REVONE_API_URL}/reports`, token),
    getOne: (id) => axios.get(`${REACT_APP_REVONE_API_URL}/reports/${id}`, token),
  },
  skills : {
    getAllSkills: () =>
    axios.get(
      `${REACT_APP_REVONE_API_URL}/lookup/skill`,
      token
    ),
  },
  subIndustries: {
    getAllSubindustries: () =>
    axios.get(
      `${REACT_APP_REVONE_API_URL}/lookup/primaryworkscopeexperience`, token
    )
  }
};

export default api
