import React from "react";
import {EditableDatagrid} from "@react-admin/ra-editable-datagrid";
import {
  BooleanInput,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  NumberInput,
  ReferenceInput,
  ReferenceManyField,
  RichTextField,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextField as FieldText,
  TextInput,
  Toolbar,
  useGetList,
  useRecordContext,
  ToolbarProps,
  Identifier
} from "react-admin";
import CustomDateInput from "../CustomDateInput";
import TextField from "@mui/material/TextField";
import {useWatch} from 'react-hook-form';

import {
  RESOURCE_CLIENTS,
  RESOURCE_JOB_COMMENTS,
  RESOURCE_JOBS_ADDRESSES,
  RESOURCE_LOOKUP_FIELDMANAGER,
  RESOURCE_PROJECTS,
} from "../../provider/restProvider";
import {AccordionForm, AccordionFormPanel} from "@react-admin/ra-form-layout";
import {LazyFormTab} from "../LazyFormTab/LazyFormTab";
import AddressRowForm from "../Candidate/AddressRowForm";
import Divider from "@mui/material/Divider";
import ReverseBooleanInput from "../ReverseBooleanInput";
import JobCandidates from "./JobCandidates";
import {MAX_RECORD_PER_PAGE, US_COUNTRY_NAME} from "../../provider/constants";
import {commonStyles} from "../CommonStyles";
import Box from "@mui/material/Box";
import {CommentCreate} from "../CommentCreate";
import {useParams} from 'react-router-dom';
import {CandidateList} from "./CandidateList";


const EditToolbar: React.FC<ToolbarProps> = (props) => (
    <Toolbar {...props}>
      <SaveButton label="Save" variant="text" />
    </Toolbar>
);

export interface JobEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

export type AddProspectProps = {
  selectedIds: Identifier[];
  jobId: string;
  resource?: string;
  isRenderContentOnly?: boolean;
  onSuccess?: () => void;
  responseInfo?: any;
};

export const calculateRateWithMsp = (rate, msp) => {
  // @ts-ignore
  return parseFloat(rate) * (1 - parseFloat(msp) / 100);
};

const RenderNetBillRates = () => {
  const msp = useWatch({name: 'msp', defaultValue: 0.0});
  const straightTimeBillRate = useWatch({name: 'straightTimeBillRate', defaultValue: 0.0});
  const overtimeBillRate = useWatch({name: 'overtimeBillRate', defaultValue: 0.0});
  const doubleTimeBillRate = useWatch({name: 'doubleTimeBillRate', defaultValue: 0.0});

  return <>
    <TextField
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Net ST Bill rate"
        sx={commonStyles.formComponentDefaultMargins}
        value={calculateRateWithMsp(straightTimeBillRate, msp) || 0}
        variant="outlined"
        fullWidth
        disabled
    />
    <TextField
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Net OT Bill rate"
        sx={commonStyles.formComponentDefaultMargins}
        value={calculateRateWithMsp(overtimeBillRate, msp) || 0}
        variant="outlined"
        disabled={true}
        fullWidth
    />

    <TextField
        style={{width: "256px", marginRight: "0.5rem"}}
        label="Net DT Bill rate"
        sx={commonStyles.formComponentDefaultMargins}
        value={calculateRateWithMsp(doubleTimeBillRate, msp) || 0}
        disabled
        variant="outlined"
        fullWidth
    />
  </>;
}

export const JobEdit: React.FC<JobEditProps> = ({
                                                  syncWithLocation,
                                                  ...props
                                                }) => {
  const {id} = useParams();
  const jobRecord = useRecordContext();

  const {data: clientData, isSuccess: clientLoaded} = useGetList(
      RESOURCE_CLIENTS,
      {
        pagination: {
          page: 1,
          perPage: MAX_RECORD_PER_PAGE,
        },
        sort: {field: "name", order: "ASC"}
      },
  );
  const {
    data: projectData,
    isSuccess: projectLoaded,
  } = useGetList(
      RESOURCE_PROJECTS,
      {
        pagination: {
          page: 1,
          perPage: MAX_RECORD_PER_PAGE
        },
        sort: {field: "name", order: "ASC"}
      },
  );

  return (
      <Edit
          {...props}
          title={syncWithLocation === undefined ? "" : " "}
          actions={false}
          mutationMode="pessimistic"
      >
        <TabbedForm
          warnWhenUnsavedChanges
          toolbar={<EditToolbar />}
          style={{ width: "100%" }}
          syncWithLocation={
            syncWithLocation === undefined ? true : syncWithLocation
          }
          sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab label="Header">
          <NumberInput
              source="id"
              label={"Job ID"}
              sx={commonStyles.formComponentDefaultMargins}
              readOnly
          />
          <SelectInput
              source="clientId"
              choices={clientLoaded ? clientData : []}
              sx={commonStyles.formComponentDefaultMargins}
          />
          <SelectInput
              source="projectId"
              choices={
                projectLoaded ? projectData : []
              }
              sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
              source="projectName"
              disabled={true}
              sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput source="jobTitle"
                     sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
              source="numberOfPositions"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
              source="startDate"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
              source="endDate"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
              source="dateAdded"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput source="region"
                     sx={commonStyles.formComponentDefaultMargins}
          />
          <ReferenceInput source="fieldManagerAssignedToId" reference={RESOURCE_LOOKUP_FIELDMANAGER}>
            <SelectInput label="Assigned Field Manager" sx={commonStyles.formComponentDefaultMargins} optionText={(record) => record.name}/>
          </ReferenceInput>
          <TextInput
              source="createdByName"
              label="Created by"
              disabled={true}
              sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
              source="updatedByName"
              label="Updated by"
              disabled={true}
              sx={commonStyles.formComponentDefaultMargins}
          />


          <BooleanInput label="Computer Required" source="computerRequired" />
          <BooleanInput label="Email Required" source="emailRequired" />

          <Divider style={{ minWidth: "100%", height: 0 }} />
          <h3 style={{ minWidth: "100%", marginBottom: "3rem", height: 0 }}>
            Payments & Billing
          </h3>
          <NumberInput
              source="perDiem"
              label="Per Diem"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
              source="msp"
              max={100}
              label="MSP (%)"
              sx={commonStyles.formComponentDefaultMargins}
          />

          <Divider style={{minWidth: '100%', marginBottom: '15px', height: 0}}/>

          <NumberInput
              source="straightTimeBillRate"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
              source="overtimeBillRate"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
              source="doubleTimeBillRate"
              sx={commonStyles.formComponentDefaultMargins}
          />

          <div
            style={{ display: "flex", width: "100%", alignItems: "baseline" }}
          >
            <RenderNetBillRates/>

          </div>
          <Divider style={{minWidth: '100%', marginTop: '15px', marginBottom: '15px', height: 0}}/>

          <NumberInput
              source="straightTimePayRate"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
              source="overtimePayRate"
              sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
              source="doubleTimePayRate"
              sx={commonStyles.formComponentDefaultMargins}
          />

          <TextInput
              multiline
              fullWidth={true}
              source="description"
              sx={{
                ...commonStyles.formComponentDefaultMargins,
                maxWidth: '80%'
              }}
          />

          <Divider style={{minWidth: '100%', margin: '0px', height: 0, borderWidth: 0}}/>
          <ReverseBooleanInput source={"isActive"} label={"Archived"}
                               sx={commonStyles.formComponentDefaultMargins}
          />
        </LazyFormTab>

        <LazyFormTab label="Addresses">
          <ReferenceManyField
            reference={RESOURCE_JOBS_ADDRESSES}
            target="addresses"
            label=""
            perPage={100}
          >
            <EditableDatagrid
                rowClick={false}
                bulkActionButtons={false}
                editForm={
                <AddressRowForm hasAddressType={false} hasUnitNumber={false} hasCity={true} />
              }
                createForm={
                <AddressRowForm
                    hasAddressType={false}
                    hasUnitNumber={false}
                    hasCity={true}
                    defaultValues={{jobId: id || jobRecord?.id, country: US_COUNTRY_NAME}}
                />
              }
                noDelete={true}
                className={"EditableGridDefaultHeader"}
            >
              <FieldText source="address" sortable={false} />
              <FieldText source={"country"} sortable={false} />
              <FieldText source="state" label='State/Province' sortable={false} />
              <FieldText source="city"  label='City/Town' sortable={false} />
              <FieldText source="zip" sortable={false} />
              <DeleteWithConfirmButton redirect={false} label={""} />
            </EditableDatagrid>
          </ReferenceManyField>
        </LazyFormTab>

        <LazyFormTab label="comments">
          <ReferenceManyField
              reference={RESOURCE_JOB_COMMENTS}
              target="comments"
              label=""
              perPage={100}
              sort={{field: "lastModifiedDate", order: "DESC"}}
          >
            <Datagrid bulkActionButtons={false}
                      sx={commonStyles.subGridDefaultStyle}
                      rowClick={false}
            >
              <RichTextField source="text" label="Comment"/>
              <RichTextField source="fieldManagerName" label="Comment Authour"/>
              <FieldText source="lastModifiedDate"/>
              <DeleteWithConfirmButton redirect={false} label={""}/>
            </Datagrid>
          </ReferenceManyField>

          <CommentCreate resource={RESOURCE_JOB_COMMENTS}/>
        </LazyFormTab>
        <LazyFormTab label="Candidates">
          <JobCandidates jobId={id || jobRecord?.id} job={jobRecord}/>
          <Box width={"100%"} height={15}/>
          <AccordionForm
              autoClose={false}
              toolbar={<> </>}
          >
            <AccordionFormPanel label="Add candidate as prospect">
              <CandidateList jobId={id || jobRecord?.id}/>
            </AccordionFormPanel>
          </AccordionForm>
        </LazyFormTab>
      </TabbedForm>
    </Edit>
  );
};
