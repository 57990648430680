import React, { useState } from 'react';
import {
  Button,
  useGetOne,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import {
  RESOURCE_CLIENT_CONTACTS,
  RESOURCE_PROJECTS,
} from '../../../provider/restProvider';
import api from '../../../provider/api';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import MultipleClientContactSelectionDialog from '../MultipleClientContactSelectionDialog';

export const AddProjectContacts = ({ projectId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isSuccess: loaded } = useGetOne(RESOURCE_PROJECTS, {
    id: projectId,
  });
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(RESOURCE_CLIENT_CONTACTS);

  const clientContactsFilter =
    loaded && data.clientId
      ? {
          clientId: {
            fieldName: 'clientId',
            fieldValue: '' + data.clientId,
            condition: '==',
          },
        }
      : undefined;

  const onSelectClientContacts = async (ids) => {
    try {
      await api.projects.assignContacts(projectId, ids);
      unselectAll();
      refresh();
      setIsOpen(false);
    } catch (err) {
      notify('Sorry, failed, try again!', { type: 'error', undoable: false });
    }
  };

  return (
    <>
      <Button
        label="Refresh"
        onClick={() => {
          refresh();
        }}
      >
        <RefreshIcon />
      </Button>
      <Button label="Add Contact" onClick={() => setIsOpen(true)}>
        <AddIcon />
      </Button>
      {isOpen && (
        <MultipleClientContactSelectionDialog
          onClose={() => setIsOpen(false)}
          filter={clientContactsFilter}
          onSelect={onSelectClientContacts}
        />
      )}
    </>
  );
};
