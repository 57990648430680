import * as React from 'react';
import { useState } from 'react';
import {
  Datagrid,
  ListContextProvider,
  ListView,
  ResourceContextProvider,
  SortPayload,
  TextField,
  useGetList,
} from 'react-admin';
import { RESOURCE_CANDIDATES } from '../../provider/restProvider';
import LinkField from '../LinkField';
import Box from '@mui/material/Box';
import CandidateMessageButton from '../Candidate/CandidateMessageButton';
import { FilterFormTitle } from './FilterFormTitle';
import { ListControllerResult } from 'ra-core/dist/cjs/controller/list/useListController';

const FilterForm = () => {
  return (
    <Box display="flex" justifyContent="space-between" m={0} width="100%">
      <FilterFormTitle>New Candidates</FilterFormTitle>
    </Box>
  );
};

const filterValues = {
  notMessaged: {
    fieldName: 'NotMessaged',
    fieldValue: 'NotMessaged',
    condition: '==',
  },
  deviceId: {
    fieldName: 'DeviceId',
    fieldValue: null,
    condition: '!=',
  },
};

export const NewCandidates = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const sort = { field: 'availabilityDate', order: 'ASC' } as SortPayload;

  const { data, total, isLoading } = useGetList(RESOURCE_CANDIDATES, {
    filter: filterValues,
    pagination: { page, perPage },
    sort,
    meta: {
      useSmallModel: true,
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //@ts-ignore Omit `setFilters` to prevent "Clear Filter" button appearing because we don't allow filters to be set
  const listCtx: ListControllerResult = {
    data,
    total,
    page,
    perPage,
    filterValues,
    sort,
    defaultTitle: ' ',
    error: null,
    isPending: false,
    displayedFilters: {},
    resource: '',
    selectedIds: [],
    setPerPage,
    setPage,
    onSelect: () => {},
    onToggleItem: () => {},
    onUnselectItems: () => {},
    refetch: () => {},
    hideFilter: () => {},
    showFilter: () => {},
    setSort: () => {},
  };

  return (
    // @ts-ignore
    <ResourceContextProvider value={RESOURCE_CANDIDATES}>
      <ListContextProvider value={listCtx}>
        <ListView actions={<FilterForm />}>
          <Datagrid
            rowClick={false}
            resource={RESOURCE_CANDIDATES}
            bulkActionButtons={false}
          >
            <LinkField
              source="id"
              label="Candidate"
              resource={RESOURCE_CANDIDATES}
            >
              <TextField source="candidateName" sortable={false} />
            </LinkField>
            <CandidateMessageButton />
          </Datagrid>
        </ListView>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};
