import { FC } from 'react';
import { Button, Login } from 'react-admin';
import Logo from '../../assets/LeftLogo.png';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../theme';

const EmailSent: FC = () => {
  const navigate = useNavigate();

  return (
    <Login title='Harmoniq HR Portal' backgroundImage={Logo}>
      <p
        style={{
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        Reset password email sent
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2rem',
          marginBottom: '3rem',
        }}
      >
        <p
          style={{
            textAlign: 'center',
            marginTop: '0.5rem',
            fontSize: '0.8rem',
            marginBottom: '3rem',
            color: colors.text.light,
            padding: '0 2rem 0 2rem',
          }}
        >
          Check your mailbox (Including spam if necessary!)
        </p>
        <Button
          label='Back to login'
          onClick={() => navigate('/login')}
          style={{
            background: colors.surface.grey,
            padding: '1rem',
          }}
        >
          <SendIcon />
        </Button>
      </div>
    </Login>
  );
};

export default EmailSent;
