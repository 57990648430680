import { FC } from 'react';
import { AppLocationContext } from "@react-admin/ra-navigation";
import { AppBar, Layout, AppBarProps, LayoutProps } from 'react-admin';
import { AppMenu, MyUserMenu } from "./components/Menu/AppMenu";
import { ProfileProvider } from "./components/Users/ProfileEdit";

const MyAppBar: FC<AppBarProps> = (props) => {
  return (
    <AppBar
      userMenu={<MyUserMenu />}
      {...props}
    />
  );
};

export const AppLayout: FC<LayoutProps> = (props) => {
  return (
    <AppLocationContext>
      <ProfileProvider>
        <Layout
          appBar={MyAppBar}
          menu={AppMenu}
          {...props}
        />
      </ProfileProvider>
    </AppLocationContext>
  );
};
