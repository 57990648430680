import { FC } from 'react';
import { Edit } from 'react-admin';
import { PermissionForm } from './PermissionForm';
import { EditPropsWithLocation } from '../types';

export const PermissionEdit: FC<EditPropsWithLocation> = ({
  syncWithLocation,
  ...props
}) => {
  return (
    <Edit
      title={syncWithLocation === undefined ? 'Role Permission Edit' : ' '}
      actions={false}
      mutationMode='pessimistic'
      {...props}
    >
      <PermissionForm syncWithLocation={syncWithLocation} />
    </Edit>
  );
}
