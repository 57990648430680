import {
  CreateButton,
  EmailField,
  Filter,
  FilterProps,
  Identifier,
  List,
  ListActionsProps,
  ListProps,
  NumberField,
  RaRecord,
  TextField,
  TopToolbar,
  useListContext,
  useStore,
} from 'react-admin';
import CustomDateInput from '../CustomDateInput';
import { colors } from '../../theme';
import { FC } from 'react';
import AddToBasketButton from './AddToBasketButton';
import { CandidateEdit } from './CandidateEdit';
import moment from 'moment';
import {
  DEFAULT_DATE_FORMAT,
  PROSPECT_CANDIDATE_SUBMITTED,
} from '../../provider/constants';
import ActionsColumn from '../ActionsColumn';
import CandidateBasketButton from './CandidateBasketButton';
import AddToContactFavoriteButton from './AddToContactFavoriteButton';
import AvatarColumnField from '../AvatarColumnField';
import LegendButton from '../LegendButton';
import CustomBooleanField from '../CustomBooleanField';
import ExportButton from '../ExportButton';
import AddToFavoriteListButton from './FavouriteList/AddToFavoriteListButton';
import SendNotificationButton from '../Job/SendNotificationButton';
import AddProspectNotifyWithJobSelectButton from '../Job/AddProspectNotifyWithJobSelectButton';
import DeleteMultipleButton from './DeleteMultipleButton';
import FreeTextSearchInput from '../FreeTextSearchInput';
import { commonStyles } from '../CommonStyles';
import { PostPagination } from '../PostPagination';
import LinkField from '../LinkField';
import { FilterSidebar } from '../Filters/FilterSidebar';
import { FormControlLabel, Switch } from '@mui/material';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';

const CandidateActions: FC<ListActionsProps> = (props) => {
  return (
    <TopToolbar {...props}>
      <FilterSidebarButton />
      <CandidateFilter context="button" />
      <CreateButton />
      <CandidateBasketButton />
      <LegendButton
        legends={[
          {
            label: 'Do not hire',
            color: colors.indicator.black,
          },
          {
            label: 'Submitted to Job(s)',
            color: colors.indicator.purple,
          },
          {
            label: 'Not available',
            color: colors.indicator.red,
          },
          {
            label: 'Available within 5 months',
            color: colors.indicator.yellow,
          },
          {
            label: 'Available [Last month - Next 2 months]',
            color: colors.indicator.green,
          },
          {
            label: 'No Availability Date',
            color: colors.indicator.grey,
          },
        ]}
      />
      <ExportButton />
      <SelectColumnsButtonAutoSave />
    </TopToolbar>
  );
};

const FilterSidebarButton: FC = () => {
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const [sideFilters, setSideFilters] = useStore(
    'candidates.sideFilters',
    false
  );

  return (
    <FormControlLabel
      label="Show Filters"
      control={
        <Switch
          size="small"
          defaultChecked={sideFilters}
          onChange={(e) => {
            setFilters(
              {
                ...filterValues,
                sideFilters: [],
              },
              {
                ...displayedFilters,
                sideFilters: e.target.checked,
              }
            );
            setSideFilters(e.target.checked);
          }}
        />
      }
    />
  );
};

type CandidateFilterProps = Omit<FilterProps, 'children'>;

const CandidateFilter: FC<CandidateFilterProps> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput
        label="Free Text Search"
        source="searchTerm"
        alwaysOn
      />
      <CustomDateInput source="availabilityStart" label="Avail Start" />
      <CustomDateInput source="availabilityEnd" label="Avail End" />
    </Filter>
  );
};

const getRowBorderColor = (record: RaRecord) => {
  const jobResponses = Array.isArray(record.jobResponses)
    ? record.jobResponses
    : [];
  const submittedJob = jobResponses.find(
    (jr) => jr.prospectStatusId === PROSPECT_CANDIDATE_SUBMITTED
  );

  if (record.doNotHire) {
    return colors.indicator.black;
  } else if (submittedJob) {
    return colors.indicator.purple;
  } else if (record['availabilityDate']) {
    const availMoment = moment(record['availabilityDate'], DEFAULT_DATE_FORMAT);
    const nowMoment = moment();
    const days = availMoment.diff(nowMoment, 'days');

    if (days < -30) {
      return colors.indicator.red;
    } else if (days < 2 * 30) {
      return colors.indicator.green;
    } else if (days < 5 * 30) {
      return colors.indicator.yellow;
    } else {
      return colors.indicator.red;
    }
  }

  return colors.indicator.grey;
};

export const candidateRowStyle = (record: RaRecord) => {
  return {
    borderLeftColor: getRowBorderColor(record),
    borderLeftWidth: 8,
    borderLeftStyle: 'solid',
  };
};

export type CandidateBulkActionButtonsProps = {
  selectedIds: Identifier[];
  onSuccess?: () => void;
};

const CandidateBulkActionButtons: FC = () => {
  const { selectedIds } = useListContext();

  return (
    <>
      <DeleteMultipleButton selectedIds={selectedIds} />
      <AddToContactFavoriteButton selectedIds={selectedIds} />
      <AddToBasketButton selectedIds={selectedIds} />
      <AddToFavoriteListButton selectedIds={selectedIds} />
      <SendNotificationButton selectedIds={selectedIds} />
      <AddProspectNotifyWithJobSelectButton selectedIds={selectedIds} />
    </>
  );
};

export const CandidateList: FC<ListProps> = (props) => {
  return (
    <List
      title="Candidates"
      {...props}
      sort={{ field: 'firstName', order: 'ASC' }}
      filters={<CandidateFilter />}
      filter={{ includeFields: ['jobResponses', 'address'] }}
      actions={<CandidateActions />}
      perPage={100}
      pagination={<PostPagination />}
      aside={
        <FilterSidebar
          availableFilters={[
            { displayName: 'Skills', apiName: 'skill' },
            { displayName: 'Clearances', apiName: 'clearance' },
            { displayName: 'Client Experiences', apiName: 'clientexperience' },
            {
              displayName: 'OEM Experience Types',
              apiName: 'oemexperiencetype',
            },
            {
              displayName: 'High Level Industries',
              apiName: 'highlevelindustry',
            },
            { displayName: 'Subindustres', apiName: 'subindustry' },
            {
              displayName: 'Work Experience Levels',
              apiName: 'workexperiencelevel',
            },
            {
              displayName: 'Work Scope Experience',
              apiName: 'primaryworkscopeexperience',
              subFilter: { displayName: 'Job Title', apiName: 'jobtitle' },
            },
          ]}
        />
      }
    >
      <DatagridConfigurableRbac
        bulkActionButtons={<CandidateBulkActionButtons />}
        rowClick="expand"
        editComponent={<CandidateEdit syncWithLocation={false} />}
        // showComponent={<CandidateShow {...props} />}
        rowSx={candidateRowStyle}
        sx={commonStyles.defaultGridStyle}
      >
        <AvatarColumnField
          source="avatarURL"
          size={25}
          label="Avatar"
          sortable={false}
        />
        <NumberField source="id" label="Candidate ID" sortable={true} />
        <TextField source="firstName" sortable={true} />
        <TextField source="lastName" sortable={true} />
        <TextField source="nickName" sortable={true} />
        <EmailField source="workEmail" sortable={true} />
        <EmailField source="personalEmail" sortable={true} />
        <LinkField phone source="homePhone" label="Home Phone">
          <TextField source="homePhone" label="Home Phone" sortable={true} />
        </LinkField>
        <LinkField phone source="mobile">
          <TextField source="mobile" sortable={true} />
        </LinkField>
        <LinkField phone source="otherPhone">
          <TextField source="otherPhone" sortable={true} />
        </LinkField>
        <TextField source="birthday" sortable={true} />
        <TextField source="referredBy" sortable={true} />
        <TextField
          source="availabilityDate"
          label="Availability"
          sortable={true}
        />
        <CustomBooleanField
          label="Do Not Hire"
          source="doNotHire"
          sortable={true}
        />
        <CustomBooleanField
          label="Military Exp"
          source="hasMilitaryExperience"
          sortable={true}
        />
        <TextField source="createdByName" label="Created By" sortable={false} />
        <TextField source="updatedByName" label="Updated By" sortable={false} />
        <CustomBooleanField
          label="Comp. Wizard"
          source="completedWizard"
          sortable={true}
        />
        <TextField source="creationDate" label="Creation" sortable={true} />
        <TextField
          source="lastModifiedDate"
          label="Last Modified"
          sortable={true}
        />
        <TextField
          source="lastLoginOnMobileAppDate"
          label="Last login in mobile app"
          sortable={true}
        />
        <TextField
          source="availabilityDateLastUpdated"
          label="Avail Last Updated"
          sortable={true}
        />
        <TextField
          source="addresses[0].address"
          label="Address"
          sortable={false}
        />
        <TextField
          source="addresses[0].unitNumber"
          label="Unit"
          sortable={false}
        />
        <TextField source="addresses[0].city" label="City" sortable={false} />
        <TextField source="addresses[0].state" label="State" sortable={false} />
        <TextField
          source="addresses[0].country"
          label="Country"
          sortable={false}
        />
        <TextField source="addresses[0].zip" label="Zip" sortable={false} />
        <ActionsColumn label="Actions" actionsSettings={{ show: false }} />
      </DatagridConfigurableRbac>
    </List>
  );
};
