import { FC } from 'react';
import { SaveButton, Toolbar, ToolbarProps } from 'react-admin';

type PermissionFormToolbarProps = Omit<ToolbarProps, 'children'>;

export const PermissionFormToolbar: FC<PermissionFormToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton type='button' />
    </Toolbar>
  );
};
