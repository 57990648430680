export const permissionTypeChoices = [
  { id: 'allow', name: 'Allow' },
  { id: 'deny', name: 'Deny' },
];

export const actionChoices = [
  { id: '*', name: 'All' },
  { id: 'list', name: 'List' },
  { id: 'create', name: 'Create' },
  { id: 'edit', name: 'Edit' },
  { id: 'show', name: 'Show' },
  { id: 'delete', name: 'Delete' },
  { id: 'export', name: 'Export' },
  { id: 'import', name: 'Import' },
];
