import { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Datagrid,
  Loading,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  useNotify,
  useRecordContext,
} from 'react-admin';
import IconClose from '@mui/icons-material/Close';
import { RESOURCE_CANDIDATE_DOCUMENTS } from '../../provider/restProvider';
import { commonStyles } from '../CommonStyles';
import ActionsColumn from '../ActionsColumn';
import ParseCVButton from '../Candidate/ParseCVButton';
import { AxiosResponse } from 'axios';
import api from '../../provider/api';
import CreateNewSkillDialog from './CreateNewSkillDialog';

interface SkillsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  unmatchedSkills?: Array<skillOrganise>;
  document?: object | boolean;
}

interface skillOrganise {
  parsedName: string;
  textKernelName: string;
  textKernelId: string;
  name?: string;
  skillId?: number;
  subindustryId?: number;
  exclude: boolean;
}
interface unmappedSkill {
  textKernelId: string;
  textKernelName: string;
  exclude: boolean;
  skillId: number;
  parentId: number;
}
interface cvResponse extends AxiosResponse {
  candidateId?: Number;
  unmappedSkills?: Array<unmappedSkill>;
}
interface skillOption {
  isNew?: boolean;
  value?: string;
  text?: string;
  parentValue?: string;
  parentText?: string;
}

export const sendCvRequest = async (id) => {
  try {
    const response: cvResponse = await api.documents.parseCv(id);
    if (response.status === 200) {
      const data = response.data;
      return Promise.resolve({
        data,
      });
    } else {
      return Promise.reject(`Api returned ${response.status}`);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const getSkills = async () => {
  try {
    const response: AxiosResponse<Array<Object>> =
      await api.skills.getAllSkills();
    if (response.status === 200) {
      const data: Array<object> = response.data;
      return Promise.resolve({
        data,
      });
    } else {
      return Promise.reject(`Api returned ${response.status}`);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const sendMappedSkills = async (candidateId, mappedSkills) => {
  try {
    const response: AxiosResponse<Array<Object>> =
      await api.documents.mapSkills(candidateId, mappedSkills);
    if (response.status === 200) {
      const data: Array<object> = response.data;
      return Promise.resolve({
        data,
      });
    } else {
      return Promise.reject(`Api returned ${response.status}`);
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const SaveSkillsToolbar = () => (
  <SaveButton label="Submit" icon={<DoneIcon />} />
);

const ImportSkillsDialog = ({
  unmatchedSkills,
  isOpen,
  onClose,
  document,
}: SkillsDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState<Array<skillOrganise | undefined>>(
    unmatchedSkills || []
  );
  const [allSkills, setAllSkills] = useState<Array<skillOption>>([]);
  const [mappedSkills, setMappedSkills] = useState([]);
  const [excludedSkills, setExcludedSkills] = useState([]);
  const notify = useNotify();
  const record = useRecordContext();
  const [candidateId] = useState(record?.id);
  const [documentPassed, setDocumentPassed] = useState(document);
  const submitSkills = (data) => {
    const mappedSkills = skills.map((skill) => {
      if (data[skill.textKernelId] !== '') {
        return {
          textKernelId: skill.textKernelId,
          textKernelName: skill.name,
          exclude: data[skill.textKernelId] === '0' ? true : false,
          skillId: allSkills[data[skill.textKernelId] - 1].isNew
            ? undefined
            : data[skill.textKernelId],
          parentId: allSkills[data[skill.textKernelId] - 1].isNew
            ? allSkills[data[skill.textKernelId] - 1].parentValue
            : undefined,
        };
      }
      return undefined;
    });
    const filteredSkills = mappedSkills.filter((item) => {
      if (item !== undefined) {
        return item;
      }
      return false;
    });
    const requestBody = {
      candidateId,
      unmappedSkills: [...filteredSkills],
    };
    sendMappedSkills(candidateId, requestBody).then(() => {
      close();
      notify('Skills successfully mapped');
    });
  };

  const close = useCallback(() => {
    setSkills([]);
    setDocumentPassed(false);
    onClose();
  }, [onClose]);

  const addSkillItem = (item) => {
    item.id = `${allSkills.length + 1}`;
    item.isNew = true;
    setAllSkills([...allSkills, item]);
  };

  const getUnmatchedSkills = useCallback(
    (documentObject) => {
      setLoading(true);
      sendCvRequest(documentObject)
        .then((response) => {
          if (
            Array.isArray(response.data.unmappedSkills) &&
            response.data.unmappedSkills.length > 0
          ) {
            const newSkills: Array<skillOrganise> =
              response.data.unmappedSkills.map((skill: skillOrganise) => {
                return {
                  parsedName: skill.textKernelName,
                  name: skill.textKernelName,
                  textKernelId: skill.textKernelId,
                  exclude: skill.exclude,
                  parentId: skill.subindustryId,
                };
              });
            setSkills(newSkills);
          } else if (
            Array.isArray(response.data.jobTitles) &&
            response.data.jobTitles.length > 0
          ) {
            notify(
              `Added Job titles:\n ${response.data.jobTitles.join('\n')}`,
              {
                type: 'info',
                undoable: false,
                multiLine: true,
              }
            );
          }

          if (
            Array.isArray(response.data.unmappedSkills) &&
            response.data?.mappedSkills.length > 0
          ) {
            setMappedSkills(response.data.mappedSkills);
          }
          if (
            Array.isArray(response.data.excludedSkills) &&
            response.data?.excludedSkills.length > 0
          ) {
            setExcludedSkills(response.data.excludedSkills);
          }

          setLoading(false);
          close();
        })
        .catch((err) => {
          notify(`Skills failed to parse, please try again: ${err}`, {
            type: 'info',
            undoable: false,
          });
          close();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [close, notify]
  );

  // Fetch all skills ready for skill matching
  useEffect(() => {
    setDocumentPassed(document);
    getSkills()
      .then((response) => {
        if (response.data.length > 0) {
          const allSkillsArray = response.data.map((skill: skillOption) => {
            return {
              id: skill.value,
              name: skill.text,
              text: skill.text,
              value: skill.value,
              // parentValue: skill.parentValue
            };
          });
          allSkillsArray.push({
            id: `0`,
            name: 'Exclude Skill',
            text: 'Exclude Skill',
            value: `0`,
          });
          setAllSkills(allSkillsArray);
        }
      })
      .catch(() =>
        notify('Failed to load in skills', { type: 'info', undoable: false })
      );

    // If document is passed run request immediately
    if (documentPassed !== false) {
      getUnmatchedSkills(documentPassed);
    }
  }, [document, documentPassed, getUnmatchedSkills, notify]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>
        {skills.length === 0 ? 'Select Document' : 'Organise Skills'}
        {loading && !documentPassed ? (
          <Loading
            loadingPrimary=""
            loadingSecondary=""
            sx={{
              height: '100% !important',
              width: '100%',
              display: 'inline',
              div: { fontSize: '0rem' },
            }}
          />
        ) : null}
      </DialogTitle>
      {documentPassed && skills.length === 0 ? (
        <DialogContent>
          <Loading
            loadingPrimary="Parsing your cv"
            loadingSecondary="Just a second..."
            sx={{ height: '100% !important', width: '100%' }}
          />
        </DialogContent>
      ) : (
        <DialogContent>
          {skills.length === 0 ? (
            /* Provide form to upload CV
                -OR-
                Select from already existing documents for this candidate */
            <Box>
              <ReferenceManyField
                reference={RESOURCE_CANDIDATE_DOCUMENTS}
                target="documents"
                label=""
                perPage={100}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <Datagrid
                  bulkActionButtons={false}
                  sx={commonStyles.subGridDefaultStyle}
                  rowClick={false}
                >
                  <TextField
                    source="documentType"
                    label="Type"
                    sx={{ textTransform: 'capitalize' }}
                  />
                  <TextField source="name" label="File Name" />

                  <ActionsColumn
                    actionsSettings={{
                      show: false,
                      edit: false,
                      delete: false,
                    }}
                  >
                    <ParseCVButton
                      label={'Select'}
                      source={'id'}
                      onClick={getUnmatchedSkills}
                    />
                  </ActionsColumn>
                </Datagrid>
              </ReferenceManyField>
            </Box>
          ) : (
            // Provide tools to organise skills
            <Box>
              <SimpleForm
                toolbar={<SaveSkillsToolbar />}
                onSubmit={submitSkills}
                noValidate
              >
                {mappedSkills.length > 0 ? (
                  <>
                    <Accordion sx={{ width: '100%' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="mappedSkills-content"
                        id="mappedSkills-header"
                      >
                        Mapped Skills
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            alignItems: 'start',
                          }}
                        >
                          {mappedSkills.map((skill) => {
                            return (
                              <Chip
                                label={skill.textKernelName}
                                sx={{ marginY: '5px' }}
                              />
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : null}
                {excludedSkills.length > 0 ? (
                  <>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="excludedSkills-content"
                        id="excludedSkills-header"
                      >
                        Excluded Skills
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            alignItems: 'start',
                          }}
                        >
                          {excludedSkills.map((skill) => {
                            return (
                              <Chip
                                label={skill.textKernelName}
                                sx={{ marginY: '5px' }}
                              />
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : null}
                <Box
                  sx={{
                    marginTop: '20px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    '& table': { width: '100%' },
                  }}
                >
                  <table>
                    <>
                      <tr>
                        <th>Unmatched skill</th>
                        <th>Skill Assigned</th>
                      </tr>
                      {skills.map((skill) => {
                        return (
                          <tr key={skill.textKernelId}>
                            <td style={{ verticalAlign: 'middle' }}>
                              {skill.parsedName}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              <SelectInput
                                source={skill.textKernelId}
                                choices={allSkills}
                                label="Select a skill"
                                defaultValue={undefined}
                                create={
                                  <CreateNewSkillDialog
                                    onSubmit={(newSkill) =>
                                      addSkillItem(newSkill)
                                    }
                                  />
                                }
                                sx={{
                                  width: '100%',
                                  '& p': {
                                    display: 'none',
                                  },
                                  '& div': {
                                    minWidth: '300px',
                                    width: '100%',
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    paddingRight: '18px',
                                  },
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </table>
                </Box>
              </SimpleForm>
            </Box>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={close} label="Close" startIcon={<IconClose />} />
      </DialogActions>
    </Dialog>
  );
};

export default ImportSkillsDialog;
