import React, { createContext, useContext, useMemo, useState } from 'react';
import {
  ImageField,
  ImageInput,
  required,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  Title,Toolbar,
  useGetIdentity,
  useNotify,
} from 'react-admin';
import {
  getUser,
  updatePhoneNumber,
  updateProfilePicture,
} from '../../provider/authProvider';
import { PasswordResetButton } from '../Candidate/PasswordResetButton';import styles from './styles';

interface profileVersionType {
  profileVersion: number;
  refreshProfile: () => void;
}

const ProfileContext = createContext<profileVersionType>({
  profileVersion: 0,
  refreshProfile: () => {},
});

export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

const ACCEPTED_FILE_FORMATS = ['.jpg', '.jpeg', '.png', '.bmp'];

export const ProfileEdit = (props) => {
  const user = getUser();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const { refreshProfile } = useProfile();
  const { isLoading, identity, refetch } = useGetIdentity();

  const onSuccess = () => {
    setSaving(false);
    notify('Your profile has been updated', { type: 'success' });
    refetch().then();
    refreshProfile();
  };

  const onFail = () => {
    setSaving(false);
    notify('Failed. Please try again!', { type: 'error' });
  };

  const handleSave = (values) => {
    setSaving(true);
    if (values.avatar.rawFile) {
      updateProfilePicture(values)
        .then((response) => {
          if (!response) {
            onFail();
          }
        })
        .catch(() => onFail());
    }
    updatePhoneNumber(values)
      .then((response) => {
        if (response) {
          onSuccess();
        } else {
          onFail();
        }
      })
      .catch(() => onFail());
  };

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  if (!user || isLoading) {
    return <></>;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <Title title="User Profile" />
      <SimpleForm
        record={identity ? identity : {}}
        toolbar={
          <Toolbar {...props}>
            <SaveButton label="Save" variant="text" />
            <PasswordResetButton record={{ userId: user.userId }} />
          </Toolbar>
        }
      >
        <TextInput source="firstName" validate={required()} disabled />
        <TextInput source="lastName" validate={required()} disabled />
        <TextInput source="email" type="email" disabled />
        <TextInput source="phoneNumber" validate={required()} />
        <ImageInput
          source="avatar"
          label="Avatar"
          accept={{ 'image/*': ACCEPTED_FILE_FORMATS }}
          sx={styles.imageInput}
        >
          <ImageField
            source="src"
            title="Avatar preview"
            sx={styles.imageField}
          />
        </ImageInput>
      </SimpleForm>
    </SaveContextProvider>
  );
};
