import { ParentMenuItemData } from './menuItems';
import NestedMenu from './NestedMenu';
import { renderMenuItem } from './utils';

export const ParentMenuItem = ({
  id,
  name,
  label,
  icon,
  items,
}: ParentMenuItemData) => (
  <NestedMenu key={id} name={name} label={label} icon={icon} id={id}>
    {items.map(renderMenuItem)}
  </NestedMenu>
);
