import {
  BooleanField,
  BooleanInput,
  Button,
  Confirm,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  FunctionField,
  Loading,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  RichTextField,
  SaveButton,
  SelectInput,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
} from 'react-admin';
import IconClose from '@mui/icons-material/Close';
import {
  RESOURCE_CLIENT_CONTACTS,
  RESOURCE_CLIENTS,
  RESOURCE_LOOKUP_FIELDMANAGER,
  RESOURCE_PLACEMENT_COMMENTS,
  RESOURCE_PLACEMENT_DOCUMENTS,
  RESOURCE_PROJECT_CONTACTS,
  RESOURCE_PROJECTS,
} from '../../provider/restProvider';
import { CommentCreate } from '../CommentCreate';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import ReverseBooleanInput from '../ReverseBooleanInput';
import TogglableTextInput from '../CustomInputs/TogglableTextInput';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import CustomDateInput from '../CustomDateInput';
import { commonStyles } from '../CommonStyles';
import api from '../../provider/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ActionsColumn from '../ActionsColumn';
import DownloadButton from '../Candidate/DownloadButton';
import { useFormState } from 'react-hook-form';

const generalTermsAndConditions = {
  businessExpenses: [
    'Pre-approval required for other business expenses prior to purchase.',
    'Pre-approval required for business expenses prior to purchase. A 10% administrative markup will be applied.',
    'N/A',
  ],
  expenses: [
    'All travel and living costs to be expensed including meals, lodging, rental car (mid-size/intermediate), fuel and other reasonable and customary expenses with receipts',
    'All travel and living costs to be expensed including meals, lodging, rental car (mid-size/intermediate), fuel and other reasonable and customary expenses with receipts. A 10% administrative markup will be applied.',
    'Per diem paid in lieu of expenses, paid 7 days per week. Per Diem will begin on employee’s first site working day.',
    'Per diem paid in lieu of expenses. Lodging per diem paid 7 days per week; Meals and Incidentals per diem paid only on days worked. Per Diem will begin on employee’s first site working day.',
    'N/A',
  ],
  rentalCar: '',
  rrHourly: [
    'Yes (Mileage or Coach Flight Only) - Supervisor Approval Required',
    'NO',
  ],
  paidRRTravel: ['Maximum of 8 hours at hourly rate each way', 'NO'],
  rev1Hardware: [
    'Laptop and email to be furnished by Rev1. Rev1 management reserves the right to reduce the cost of unreturned hardware from the employee`s final check.',
    'N/A',
  ],
  candidateMiscText: '',
  employerMiscText: '',
};

const mobilizationAndDemobilazation = {
  airFare: [
    'Coach class airfare reimbursed with receipt',
    'Coach class airfare reimbursed with receipt. A 10% administrative markup will be applied.',
    'N/A',
  ],
  vehicleUse: [
    'Personal vehicle will be reimbursed for mobilization and demobilization only.',
    'N/A',
  ],
  mileage: [
    'Mileage reimbursed at the max allowable IRS rate. Google map required.',
    'N/A',
  ],
  paidTravelTime: [
    'Maximum of 8 hours per day at hourly rate for greater than 500 miles. Less than 500 miles will be prorated',
    'N/A',
  ],
  hotelsAndMeals: [
    'Reasonable and customary with itemized receipts',
    'Reasonable and customary with itemized receipts. A 10% administrative markup will be applied.',
    'N/A',
  ],
};
const billingInformation = {
  hourlyAndOvertimeText: [
    '{hourlyRate} ST/hour for up to and including 40 hours/week {overtimeRate} OT/hour for hours worked over 40/week',
  ],
  doubleTimeText: ['Double time at {doubleTimeRate}'],
  paymentTerms: ['All payment due Net Thirty (30) from date of receipt.'],
  foreignTax: [
    'All associated non-US tax liability will be paid directly by the customer',
  ],
  otherCosts: [
    'Visas, work permits and non-US work related costs billed at cost.',
  ],
};

const EditToolbar: React.FC = (props) => {
  const { isValid } = useFormState();

  return (
    <Toolbar {...props}>
      <SaveButton label="Save" variant="text" disabled={!isValid} />
    </Toolbar>
  );
};

export interface PlacementEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

const showPdf = (pdf) => {
  var newBlob = new Blob([pdf], { type: 'application/pdf;base64' });
  window.open(URL.createObjectURL(newBlob));
};

export const PlacementEdit: React.FC<PlacementEditProps> = ({
  syncWithLocation,
  ...props
}) => {
  return (
    <Edit
      {...props}
      title={syncWithLocation === undefined ? '' : ' '}
      actions={false}
      mutationMode="pessimistic"
      transform={(data) => {
        [
          ...Object.keys(generalTermsAndConditions),
          ...Object.keys(mobilizationAndDemobilazation),
          ...Object.keys(billingInformation),
        ].forEach((key) => {
          if (data[key + '-enabled'] === false) {
            data[key] = null;
          }
        });
        return data;
      }}
    >
      <EditForm syncWithLocation={syncWithLocation} />
    </Edit>
  );
};

const AdditionalInputs = ({ fields }) => {
  return fields.map((field, index) => (
    <TogglableTextInput {...field} key={index} required />
  ));
};

const EditForm = ({ syncWithLocation }) => {
  const notify = useNotify();
  const record = useRecordContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [letterLink, setLetterLink] = useState('');

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    notify('Copied to clipboard!');
  };
  const getPreviewLetter = async (type, id) => {
    let request;
    switch (type) {
      case 'candidate':
        request = api.documents.getCandidatePreview;
        break;
      case 'employer':
        request = api.documents.getEmployerPreview;
    }
    try {
      const response = await request(id);
      if (response.status === 200) {
        showPdf(response.data);
      } else {
        notify('Unable to fetch preview ' + response.message);
      }
    } catch (e) {
      notify(
        'Unable to fetch preview ' +
          JSON.parse(new TextDecoder().decode(e.response.data)).message || e
      );
    }
  };
  const sendLetter = async (type, id) => {
    setIsLoading(true);
    setDialogOpen(true);
    setConfirmDialog({ open: false, type: '' });
    let request;
    switch (type) {
      case 'candidate':
        request = api.documents.sendCandidateLetter;
        break;
      case 'employer':
        request = api.documents.sendEmployerLetter;
    }
    try {
      const response = await request(id);
      if (response.status === 200) {
        setLetterLink(response.data);
      } else {
        setDialogOpen(false);
        notify('Unable to send letter ' + response.message);
      }
    } catch (e) {
      setDialogOpen(false);
      notify('Unable to send letter ' + e.response.data.message);
    }
    setIsLoading(false);
  };
  const generateFieldArray = (fields) => {
    return Object.keys(fields).map((key) => {
      if (fields[key] instanceof Array) {
        if (record?.[key] !== null) {
          return {
            fieldName: key,
            enabled: true,
            defaultValue: record?.[key],
            choices: fields[key],
          };
        } else {
          // Take first choice as default if multiple options exist
          return {
            fieldName: key,
            enabled: false,
            defaultValue: fields[key][0],
            choices: fields[key],
          };
        }
      }
      // Otherwise just return the string default
      if (record?.[key] !== null) {
        return { fieldName: key, enabled: true, defaultValue: record?.[key] };
      } else {
        return { fieldName: key, enabled: false, defaultValue: fields[key] };
      }
    });
  };
  return (
    <>
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<EditToolbar />}
        style={{ width: '100%' }}
        syncWithLocation={
          syncWithLocation === undefined ? true : syncWithLocation
        }
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab label="Header">
          <NumberInput
            source="id"
            label={'Placement ID'}
            sx={commonStyles.formComponentDefaultMargins}
            readOnly
          />
          <TextInput
            source="candidateName"
            sx={commonStyles.formComponentDefaultMargins}
            disabled
          />
          <TextInput
            source="jobTitle"
            sx={commonStyles.formComponentDefaultMargins}
            disabled
          />

          <CustomDateInput
            source="startDate"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
            source="endDate"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
            source="duration"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <Box width={'100%'} height={'5px'} />
          <CustomDateInput
            label="Onboarding Complete"
            source="onboardingComplete"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
            label="Background Check Complete"
            source="backgroundCheckComplete"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
            label="Physical Complete"
            source="physicalComplete"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
            label="Drug Screen Complete"
            source="drugScreenComplete"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
            label="Rev1 Training Complete"
            source="trainingComplete"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomDateInput
            label="Client Training Complete"
            source="clientTrainingComplete"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <Box width={'100%'} height={'20px'} />
          <NumberInput
            source="straightTimeBillRate"
            sx={commonStyles.formComponentDefaultMargins}
            validate={[required()]}
          />
          <NumberInput
            source="overtimeBillRate"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
            source="doubleTimeBillRate"
            label="Double time bill rate"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
            source="straightTimePayRate"
            sx={commonStyles.formComponentDefaultMargins}
            validate={[required()]}
          />
          <NumberInput
            source="overtimePayRate"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
            source="doubleTimePayRate"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <NumberInput
            source="perDiem"
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source="rotationId"
            sx={commonStyles.formComponentDefaultMargins}
            disabled={true}
          />
          <TextInput
            source="rotationStartDate"
            sx={commonStyles.formComponentDefaultMargins}
            disabled={true}
          />
          <BooleanInput
            label="Computer Required"
            source="computerRequired"
            sx={{ '> label': { maxWidth: '230px' } }}
          />
          <BooleanInput
            label="Email Required"
            source="emailRequired"
            sx={{ '> label': { maxWidth: '230px' } }}
          />
          <BooleanInput
            label="Bypass Client Training"
            source="bypassClientTraining"
            sx={{ '> label': { maxWidth: '230px' } }}
          />
          <ReferenceInput
            link={false}
            label="Client Contact"
            source="clientContactId"
            reference={RESOURCE_CLIENT_CONTACTS}
            filter={{
              clientId: {
                fieldName: 'clientId',
                fieldValue: '' + record?.clientId,
                condition: '==',
              },
            }}
          >
            <SelectInput
              sx={commonStyles.formComponentDefaultMargins}
              optionText={(record) => `${record.firstName} ${record.lastName}`}
              label="Client Contact"
            />
          </ReferenceInput>

          <ReferenceInput
            link={false}
            label="Project Contact"
            source="projectContactId"
            reference={RESOURCE_PROJECT_CONTACTS}
            filter={{ projectId: record.projectId }}
          >
            <SelectInput
              sx={commonStyles.formComponentDefaultMargins}
              optionText={(record) => `${record.firstName} ${record.lastName}`}
              label="Project Contact"
            />
          </ReferenceInput>

          <ReferenceInput
            label="Field Manager"
            source="fieldManagerId"
            reference={RESOURCE_LOOKUP_FIELDMANAGER}
            link={false}
            sortable={false}
          >
            <SelectInput
              sx={commonStyles.formComponentDefaultMargins}
              optionText="name"
            />
          </ReferenceInput>

          <ReverseBooleanInput
            source={'isActive'}
            label={'Archived'}
            sx={commonStyles.formComponentDefaultMargins}
          />
        </LazyFormTab>
        <LazyFormTab label="comments">
          <ReferenceManyField
            reference={RESOURCE_PLACEMENT_COMMENTS}
            target="comments"
            label=""
            perPage={100}
            sort={{ field: 'lastModifiedDate', order: 'DESC' }}
          >
            <Datagrid
              bulkActionButtons={false}
              sx={commonStyles.subGridDefaultStyle}
              rowClick={false}
            >
              <RichTextField source="text" label="Comment" />
              <TextField source="fieldManagerName" label="Comment Author" />
              <TextField source="lastModifiedDate" />
              <DeleteWithConfirmButton redirect={false} label={''} />
            </Datagrid>
          </ReferenceManyField>

          <CommentCreate resource={RESOURCE_PLACEMENT_COMMENTS} />
        </LazyFormTab>

        <LazyFormTab label="Job Info">
          <ReferenceField label="" source="jobId" reference="jobs">
            <Box display="flex" sx={{ minWidth: '100%' }}>
              <Box flex={1} mr="0.5em">
                <SimpleShowLayout>
                  <TextField label="Job ID" source="id" />
                  <TextField label="Job Title" source="jobTitle" />
                  <ReferenceField
                    source={'clientId'}
                    reference={RESOURCE_CLIENTS}
                    link={'edit'}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField
                    source={'projectId'}
                    reference={RESOURCE_PROJECTS}
                    link={'edit'}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="numberOfPositions" />
                  <TextField source="startDate" />
                  <TextField source="endDate" />
                  <TextField source="perDiem" />
                  <TextField source="msp" />
                </SimpleShowLayout>
              </Box>
              <Box flex={1} ml="0.5em">
                <SimpleShowLayout>
                  <TextField source="straightTimeBillRate" />
                  <TextField source="overtimeBillRate" />
                  <TextField source="doubleTimeBillRate" />
                  <TextField source="straightTimePayRate" />
                  <TextField source="overtimePayRate" />
                  <TextField source="doubleTimePayRate" />
                  <TextField source="description" />
                  <TextField source="region" />
                  <BooleanField source="computerRequired" />
                  <BooleanField source="emailRequired" />
                </SimpleShowLayout>
              </Box>
            </Box>
          </ReferenceField>
        </LazyFormTab>
        <LazyFormTab
          label="Additional Info"
          sx={{ '> div': { width: '100%' } }}
        >
          <h3>General Terms And Conditions</h3>
          <AdditionalInputs
            fields={generateFieldArray(generalTermsAndConditions)}
          />
          <h3>Mobiliazation and Demobiliazation</h3>
          <AdditionalInputs
            fields={generateFieldArray(mobilizationAndDemobilazation)}
          />
          <h3>Billing Information</h3>
          <AdditionalInputs fields={generateFieldArray(billingInformation)} />
        </LazyFormTab>
        <LazyFormTab label="Documents">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'spaceAround',
              width: '100%',
            }}
          >
            <Box
              sx={{
                minWidth: '50%',
                minHeight: '100px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRight: '1px solid grey',
                '> h2': {
                  marginBottom: '0',
                },
              }}
            >
              <h2>Candidate Letter</h2>
              <p>Letter sent to candidate upon offer of placement</p>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                }}
              >
                <Button
                  label="Preview"
                  onClick={() => getPreviewLetter('candidate', record?.id)}
                />
                <Button
                  label="Send"
                  onClick={() =>
                    setConfirmDialog({ open: true, type: 'candidate' })
                  }
                  color="primary"
                />
              </Box>
            </Box>
            <Box
              sx={{
                minWidth: '50%',
                minHeight: '100px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                '> h2': {
                  marginBottom: '0',
                },
              }}
            >
              <h2>Employer Letter</h2>
              <p>Letter sent to employer upon offer of placement</p>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                }}
              >
                <Button
                  label="Preview"
                  onClick={() => getPreviewLetter('employer', record?.id)}
                />
                <Button
                  label="Send"
                  onClick={() =>
                    setConfirmDialog({ open: true, type: 'employer' })
                  }
                  color="primary"
                />
              </Box>
            </Box>
            <Box
              sx={{
                minWidth: '100%',
                minHeight: '100px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'start',
                borderTop: '1px solid grey',
                marginTop: '10px',
                '> h2': {
                  marginBottom: '0',
                },
              }}
            >
              <h2>Previously sent documents</h2>
              <p>Any unsigned documents for this placement will appear here</p>
              <ReferenceManyField
                reference={RESOURCE_PLACEMENT_DOCUMENTS}
                target="documents"
                label=""
                perPage={100}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <Datagrid
                  rowClick={false}
                  bulkActionButtons={false}
                  rowSx={() => ({ '& button': { backgroundColor: 'red' } })}
                  sx={{
                    width: '100%',
                    '& th': {
                      textAlign: 'left !important',
                      padding: '6px 16px !important',
                    },
                  }}
                >
                  <TextField
                    source="documentType"
                    label="Type"
                    sx={{ textTransform: 'capitalize' }}
                  />
                  <TextField source="name" label="File Name" />
                  <DateField source="creationDate" />
                  <FunctionField
                    label="Sign link"
                    render={(record) =>
                      record.signatureURL ? (
                        <Button
                          label="Copy link to sign"
                          onClick={() => copyLink(record.signatureURL)}
                        />
                      ) : (
                        'No signature link available'
                      )
                    }
                  />
                  <ActionsColumn
                    actionsSettings={{
                      show: false,
                      edit: false,
                      delete: false,
                    }}
                  >
                    <DownloadButton source="url" label="Download" />
                  </ActionsColumn>
                </Datagrid>
              </ReferenceManyField>
            </Box>
          </Box>
        </LazyFormTab>
      </TabbedForm>
      <Dialog open={dialogOpen}>
        <DialogTitle>
          {isLoading ? 'Document Sending' : 'Document Sent'}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <p>
                Letter Successfully sent! Click below to copy a link to sign the
                document.
              </p>
              <Button label="Copy Link" onClick={() => copyLink(letterLink)} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            label="Close"
            startIcon={<IconClose />}
          />
        </DialogActions>
      </Dialog>
      <Confirm
        isOpen={confirmDialog.open}
        title={`Send Letter?`}
        content={`Sending this letter is irreversible. Emails will be sent to both the ${confirmDialog.type} and the field manager.`}
        onConfirm={() => sendLetter(confirmDialog.type, record?.id)}
        onClose={() => setConfirmDialog({ open: false, type: '' })}
        confirm="Send"
      />
    </>
  );
};
