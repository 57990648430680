import { FC } from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { PermissionType } from './types';
import { convertResourceKeyToName } from './helpers';

type ResourceFieldProps = Omit<FunctionFieldProps, 'render'>;

export const ResourceField: FC<ResourceFieldProps> = (props) => {
  return (
    <FunctionField
      render={render}
      {...props}
    />
  );
};

function render(permission: PermissionType) {
  return convertResourceKeyToName(permission.resource);
}
