import * as React from 'react';
import { useState } from 'react';
import {
  BooleanInput,
  Datagrid,
  Form,
  ListContextProvider,
  ListView,
  ResourceContextProvider,
  SelectInput,
  SortPayload,
  TextField,
  useGetList,
  useListContext,
} from 'react-admin';
import {
  RESOURCE_CANDIDATES,
  RESOURCE_PLACEMENTS,
} from '../../provider/restProvider';
import { convertDateToStr } from '../../provider/UtilityFunctions';
import LinkField from '../LinkField';
import Box from '@mui/material/Box';
import { placementRowStyle } from '../Placements/PlacementsList';
import { ListControllerResult } from 'ra-core/dist/cjs/controller/list/useListController';
import { ALL_1970, pastChoices } from './constants';
import { styles } from './styles';
import { FilterFormTitle } from './FilterFormTitle';

const isPendingFilter = {
  fieldName: 'IsPending',
  fieldValue: 'true',
  condition: '==',
};

const FilterForm = () => {
  const [includeIsPendingFilter, setIncludeIsPendingFilter] = useState(false);

  const { filterValues, setFilters } = useListContext();

  return (
    <Box display="flex" justifyContent="space-between" m={0} width="100%">
      <FilterFormTitle>Placements Ongoing</FilterFormTitle>
      <Form>
        <Box display="flex" alignItems="center">
          <BooleanInput
            source="Pending"
            label="Pending"
            defaultValue={includeIsPendingFilter}
            onClick={() => {
              const newPendingValue = !includeIsPendingFilter;
              setIncludeIsPendingFilter(newPendingValue);

              const filters = {
                startDate: {
                  fieldName: 'startDate',
                  fieldValue: filterValues.startDate.fieldValue,
                  condition: '>=',
                },
              };

              if (newPendingValue) {
                filters['isPending'] = isPendingFilter;
              }
              setFilters(filters, {});
            }}
          />
          <SelectInput
            label="Started within"
            source="startDate"
            margin="dense"
            choices={pastChoices}
            sx={styles.selectInput}
            defaultValue={filterValues.startDate.fieldValue}
            emptyText="All"
            emptyValue={ALL_1970}
            onChange={(event) => {
              const filters = {
                startDate: {
                  fieldName: 'startDate',
                  fieldValue: event.target.value as string,
                  condition: '>=',
                },
              };
              if (includeIsPendingFilter) {
                filters['isPending'] = isPendingFilter;
              }
              setFilters(filters, {
                startDate: true,
                isPending: includeIsPendingFilter,
              });
            }}
          />
        </Box>
      </Form>
    </Box>
  );
};

export const PlacementOnGoing: React.FC = () => {
  const today = convertDateToStr(new Date());
  const defaultFilter = {
    endDate: {
      fieldName: 'endDate',
      fieldValue: today,
      condition: '>=',
    },
    startDate: {
      fieldName: 'startDate',
      fieldValue: ALL_1970,
      condition: '>=',
    },
    isActive: {
      fieldName: 'IsActive',
      fieldValue: 'true',
      condition: '==',
    },
  };

  const [filter, setFilter] = useState(defaultFilter);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const sort = { field: 'startDate', order: 'ASC' } as SortPayload;
  const { data, total } = useGetList(RESOURCE_PLACEMENTS, {
    filter,
    pagination: { page, perPage },
    sort,
    meta: {
      useSmallModel: true,
    },
  });

  const setFilters = (newFilters) => {
    setFilter({ ...defaultFilter, ...newFilters });
  };

  // @ts-ignore
  const value: ListControllerResult = {
    data,
    total,
    page,
    perPage,
    setPerPage,
    setPage,
    filterValues: filter,
    setFilters,
    sort,
  };

  return (
    // @ts-ignore
    <ResourceContextProvider value={RESOURCE_CANDIDATES}>
      <ListContextProvider value={value}>
        <ListView actions={<FilterForm />} title=" ">
          <Datagrid
            rowClick={false}
            resource={RESOURCE_CANDIDATES}
            bulkActionButtons={false}
            rowSx={placementRowStyle}
          >
            <LinkField
              source="candidateId"
              label="Candidate"
              resource={RESOURCE_CANDIDATES}
            >
              <TextField source="candidateName" sortable={false} />
            </LinkField>
            <LinkField
              source="id"
              label="Job Title"
              resource={RESOURCE_PLACEMENTS}
            >
              <TextField source="jobTitle" sortable={false} />
            </LinkField>
            <TextField source="startDate" sortable={false} />
            <TextField source="endDate" sortable={false} />
          </Datagrid>
        </ListView>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};
