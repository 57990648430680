import {
  RESOURCE_CANDIDATES,
  RESOURCE_JOB_RESPONSES,
} from '../../provider/restProvider';
import {
  Datagrid,
  Link,
  ListContextProvider,
  ListView,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import React, { useState } from 'react';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import ActionsColumn from '../ActionsColumn';
import CandidateMessageButton from '../Candidate/CandidateMessageButton';
import { isNotEmpty } from '../../provider/UtilityFunctions';
import DownloadButton from '../Candidate/DownloadButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { candidateRowStyle } from '../Candidate/CandidateCommon';
import { Identifier } from 'ra-core';
import { JobResponseUpdateButton } from './JobResponseUpdateButton';
import { ListControllerResult } from 'ra-core/dist/cjs/controller/list/useListController';
import { BulkActionButtons } from './components/BulkActionButtons';

const CandidateLinkField: React.FC<FieldProps> = () => {
  const record = useRecordContext();
  return (
    <Link to={`/${RESOURCE_CANDIDATES}/${record?.candidateId}`}>
      <TextField source="candidateName" sortable={false} />
    </Link>
  );
};

const StartChatActionButton = () => <CandidateMessageButton />;

const JobCandidates = ({ jobId, ...rest }) => {
  const jobRecord = useRecordContext();
  const projectId = isNotEmpty(jobRecord?.projectId)
    ? jobRecord?.projectId
    : undefined;

  const [selectedIds, setSelectedIds] = useState<Identifier[]>([]);
  const filter = {
    jobId: { fieldName: 'jobId', fieldValue: '' + jobId, condition: '==' },
    projectId,
  };

  const onSelect = (val) => {
    setSelectedIds(val);
  };
  const onToggleItem = (item) => {
    if (selectedIds.includes(item)) {
      //Remove
      setSelectedIds(selectedIds.filter((id) => id !== item));
    } else {
      //Insert
      setSelectedIds([...selectedIds, item]);
    }
  };
  const onUnselectItems = () => {
    setSelectedIds([]);
  };

  const sort = { field: 'id', order: 'ASC' as const };
  const { data, total, isLoading } = useGetList(RESOURCE_JOB_RESPONSES, {
    filter,
    pagination: { page: 1, perPage: MAX_RECORD_PER_PAGE },
    sort,
  });

  // @ts-ignore
  const value: ListControllerResult = {
    data,
    total,
    page: 1,
    perPage: MAX_RECORD_PER_PAGE,
    filterValues: filter,
    sort,
    selectedIds,
    onSelect,
    onToggleItem,
    onUnselectItems,
  };

  return (
    <Box>
      <ListContextProvider value={value}>
        <ListView title={' '} pagination={false} actions={false}>
          {isLoading ? (
            <Box display={'flex'} justifyContent={'center'} margin={'20px'}>
              <CircularProgress />
            </Box>
          ) : (
            <Datagrid
              rowClick={false}
              empty={<Box margin={'10px'}> No candidate found </Box>}
              rowSx={candidateRowStyle}
              bulkActionButtons={<BulkActionButtons jobId={jobId} />}
            >
              <CandidateLinkField source={'candidateName'} sortable={false} />
              <TextField source="status" sortable={false} />
              <TextField source="jobTitle" sortable={false} />
              <TextField
                source="clientContactName"
                label={'Contact'}
                sortable={false}
              />
              <ActionsColumn
                actionsSettings={{ show: false, edit: false, delete: false }}
              >
                <DownloadButton source="rtrDocumentUrl" label="" />
                <StartChatActionButton />
                <JobResponseUpdateButton jobRecord={jobRecord} />
              </ActionsColumn>
            </Datagrid>
          )}
        </ListView>
      </ListContextProvider>
    </Box>
  );
};

export default JobCandidates;
