import {
  CreateButton,
  List,
  ListProps,
  NumberField,
  TextField,
  TopToolbar,
} from 'react-admin';
import ActionsColumn from '../ActionsColumn';
import React from 'react';
import { FavouriteListEdit } from './FavouriteListEdit';
import { PostPagination } from '../PostPagination';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';

const FavouriteActions = () => (
  <TopToolbar>
    <CreateButton />
    <SelectColumnsButtonAutoSave />
  </TopToolbar>
);

export const FavouriteList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Favorite Lists"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      actions={<FavouriteActions />}
      perPage={50}
      pagination={<PostPagination />}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        expandSingle={true}
        // optimized={true}
        editComponent={
          <FavouriteListEdit {...props} syncWithLocation={false} />
        }
        bulkActionButtons={false}
      >
        <NumberField source="id" label={'ID'} sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField
          source="lastModifiedDate"
          label={'Last Modified'}
          sortable={false}
        />
        <TextField
          source="fieldManagerUpdatedName"
          label={'Updated by'}
          sortable={false}
        />
        <TextField source="creationDate" label={'Created'} sortable={false} />
        <TextField
          source="fieldManagerCreatedName"
          label={'Created by'}
          sortable={false}
        />

        <ActionsColumn label={'Actions'} actionsSettings={{ show: false }} />
      </DatagridConfigurableRbac>
    </List>
  );
};
