import { FC } from 'react';
import {
  DatagridConfigurable,
  DeleteWithConfirmButton,
  EmailField,
  List,
  ListProps,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { UserListActions } from './UserListActions';
import { UserFilter } from './UserFilter';
import {
  RESOURCE_CLIENTS_RA,
  RESOURCE_ROLES_RA,
} from '../../provider/restProvider';
import { commonStyles } from '../CommonStyles';
import ActionsColumn from '../ActionsColumn';
import { PostPagination } from '../PostPagination';

type UserListProps = Omit<ListProps, 'children'>;

export const UserList: FC<UserListProps> = (props) => {
  return (
    <List
      title="Users"
      actions={<UserListActions />}
      filters={<UserFilter />}
      pagination={<PostPagination />}
      sort={{ field: 'firstName', order: 'ASC' }}
      perPage={50}
      sx={commonStyles.raFilterForm}
      {...props}
    >
      <DatagridConfigurable bulkActionButtons={false} rowClick={false}>
        <TextField source="title" label="Title" sortable={false} />
        <TextField source="firstName" label="First Name" />
        <TextField source="lastName" label="Last Name" />
        <EmailField source="email" label="Email" sortable={false} />
        <ReferenceArrayField
          source="clients"
          reference={RESOURCE_CLIENTS_RA}
          label="Clients"
          sortable={false}
        >
          <SingleFieldList linkType={false} />
        </ReferenceArrayField>
        <ReferenceArrayField
          source="roles"
          reference={RESOURCE_ROLES_RA}
          label="Roles"
          sortable={false}
        >
          <SingleFieldList linkType="show" />
        </ReferenceArrayField>
        <TextField source="phone" label="Phone Number" sortable={false} />
        <ActionsColumn
          label="Actions"
          actionsSettings={{ show: false, edit: false }}
          customDeleteButton={
            <DeleteWithConfirmButton
              label=""
              redirect={false}
              confirmTitle="Delete user"
              confirmContent="Are you sure you want to delete this user?"
            />
          }
        ></ActionsColumn>
      </DatagridConfigurable>
    </List>
  );
};
