import { Button, Empty, Link } from 'react-admin';
import { RESOURCE_CLIENT_CONTACTS } from '../../../provider/restProvider';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

export const EmptyContactsView = ({ clientId }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Empty resource={RESOURCE_CLIENT_CONTACTS} hasCreate={false} />
      <Button
        component={Link}
        to={{
          pathname: `/${RESOURCE_CLIENT_CONTACTS}/create`,
          search: `?clientId=${clientId}`,
        }}
        label="Create"
      >
        <AddIcon />
      </Button>
    </div>
  );
};
