import { FC } from 'react';
import { Filter, FilterProps, SearchInput, TextInput } from 'react-admin';

type PermissionFilterProps = Omit<FilterProps, 'children'>;

export const PermissionFilter: FC<PermissionFilterProps> = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source='q' alwaysOn>
        <TextInput source='q' label='Search' />,
      </SearchInput>
    </Filter>
  );
};
