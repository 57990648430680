import { FC } from 'react';
import { TopToolbar, ToolbarProps, CreateButton } from 'react-admin';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';

type UserListActionsProps = Omit<ToolbarProps, 'children'>;

export const UserListActions: FC<UserListActionsProps> = (props) => {
  return (
    <TopToolbar {...props}>
      <SelectColumnsButtonAutoSave />
      <CreateButton />
    </TopToolbar>
  );
};
