import * as React from 'react';
import Box from '@mui/material/Box';
import { AvailableCandidates } from './AvailableCandidates';
import Divider from '@mui/material/Divider';
import { useCanAccess } from '@react-admin/ra-rbac';
import { RESOURCE_CANDIDATES } from '../../provider/restProvider';
import { PlacementEnding } from './PlacementEnding';
import { PlacementOnGoing } from './PlacementOnGoing';
import { NewCandidates } from './NewCandidates';
import { Title } from 'react-admin';

export const Dashboard = () => {
  const { canAccess } = useCanAccess({
    action: 'read',
    resource: RESOURCE_CANDIDATES,
  });

  return (
    <>
      <Title title="Dashboard" />
      {canAccess && (
        <Box display="flex" flexWrap="wrap" style={{ overflow: 'hidden' }}>
          <Box flex={1} mr="0.5em">
            <NewCandidates />
          </Box>

          <Box flex={1} ml="0.5em" style={{ overflow: 'hidden' }}>
            <AvailableCandidates />
          </Box>

          <Divider style={{ minWidth: '100%', margin: '15px', height: 0 }} />

          <Box flex={1} mr="0.5em" style={{ overflow: 'hidden' }}>
            <PlacementEnding />
          </Box>

          <Box flex={1} ml="0.5em" style={{ overflow: 'hidden' }}>
            <PlacementOnGoing />
          </Box>
        </Box>
      )}
    </>
  );
};
