import { FC } from 'react';
import {
  IconMenu,
  MenuItemList,
  MenuItemNodeProps,
  MultiLevelMenu,
} from '@react-admin/ra-navigation';
import { commonStyles } from '../CommonStyles';
import { CardContent } from '@mui/material';
import { useSidebarState } from 'react-admin';
import { MenuItemCategoryProps } from '@react-admin/ra-navigation/src/multi-level-menu/MenuItemCategory';

const NestedMenuFull: FC<MenuItemNodeProps> = ({ children, ...props }) => {
  return (
    <MultiLevelMenu.Item {...props} sx={commonStyles.sideMenuMultiLevelItem}>
      {children}
    </MultiLevelMenu.Item>
  );
};

const NestedMenuCollapsed: FC<MenuItemCategoryProps> = ({
  children,
  ...props
}) => {
  return (
    <IconMenu.Item {...props} sx={commonStyles.iconMenuItemCollapsed}>
      <CardContent key="global_settings_menu">
        <MenuItemList>{children}</MenuItemList>
      </CardContent>
    </IconMenu.Item>
  );
};

const NestedMenu: FC<MenuItemNodeProps> = (props) => {
  const [isSidebarOpen] = useSidebarState();
  return isSidebarOpen ? (
    <NestedMenuFull {...props} />
  ) : (
    <NestedMenuCollapsed {...props} />
  );
};

export default NestedMenu;
