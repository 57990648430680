import { FC } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { permissionTypeChoices } from './constants';
import { commonStyles } from '../CommonStyles';

type PermissionInputProps = Omit<SelectInputProps, 'source' | 'choices'>;

export const PermissionInput: FC<PermissionInputProps> = (props) => {
  return (
    <SelectInput
      source='permissionType'
      label='Permission Type'
      choices={permissionTypeChoices}
      sx={commonStyles.formComponentDefaultMargins}
      {...props}
    />
  );
};
