import React from 'react';
import { Button, Link, useRecordContext } from 'react-admin';
import { isEmpty } from '../../../provider/UtilityFunctions';
import { RESOURCE_CLIENT_CONTACTS } from '../../../provider/restProvider';
import EditIcon from '@mui/icons-material/Edit';

interface CustomEditButtonProps {
  clientId?: string | number;
  projectId?: string | number;
}

export const EditButton: React.FC<CustomEditButtonProps> = ({
  clientId,
  projectId,
}) => {
  const record = useRecordContext();
  let search = '';
  if (clientId) {
    search = `${isEmpty(search) ? '?' : search + '&'}clientId=${clientId}`;
  }
  if (projectId) {
    search = `${isEmpty(search) ? '?' : search + '&'}projectId=${projectId}`;
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: `/${RESOURCE_CLIENT_CONTACTS}/${record?.id}`,
        search,
      }}
      label=""
    >
      <EditIcon />
    </Button>
  );
};
