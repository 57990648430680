import { FC, useEffect, useState } from 'react';
import { Button, Login, useNotify } from 'react-admin';
import Logo from '../../assets/LeftLogo.png';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import { ReactComponent as RedExclamation } from '../../assets/icon_infowarning_red.svg';
import { ReactComponent as SuccessIcon } from '../../assets/success_tick.svg';
import api from '../../provider/api';
import { useLocation, useNavigate } from 'react-router-dom';
import './ResetPassword.css';
import { colors } from '../../theme';
import { AxiosError } from 'axios';

const classes = {
  inlineBlock: {
    display: 'inline-block !important',
    marginRight: '0.5rem',
    verticalAlign: 'middle',
  },
};

const ResetPassword: FC = () => {
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const notify = useNotify();

  const resetPassword = async () => {
    try {
      const response = await api.auth.resetPassword({
        token,
        password,
        confirmPassword: repeatPassword,
      });
      const { data } = response;
      if (data) {
        navigate('/password-reset-confirmation');
        notify(data.message, { type: 'success', undoable: false });
      }
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err?.response?.data?.message) {
        notify(`${err?.response?.data?.message}. Try again, please!`, { type: 'error', undoable: false });
      } else {
        notify('Try again, please!', { type: 'error', undoable: false });
      }
    }
    return undefined;
  };

  useEffect(() => {
    const search = location.search.split('?token=')[1];
    if (search) {
      const pathnameToken = search.split('&mobile=true')[0];
      setToken(pathnameToken);
    }
  }, [location]);

  const checkIfStringHasSpecialChar = (input: string) => {
    const spChar = '/!@#$%^&*()[]{};:\'"\\|,.<>/?-=_+/';
    for (var i = 0; i < input?.length; i++) {
      if (spChar.indexOf(input.charAt(i)) !== -1) {
        return true;
      }
    }
    return false;
  };

  return (
    <Login
      title='Harmoniq HR Portal'
      backgroundImage={Logo}
    >
      <p
        style={{
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        Enter new password and confirm.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '2rem',
          marginBottom: '3rem',
        }}
      >
        <TextField
          style={{ width: '256px', marginBottom: '1rem' }}
          label='New password'
          sx={classes.inlineBlock}
          type='password'
          value={password}
          variant='outlined'
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          style={{ width: '256px', marginBottom: '0.5rem' }}
          label='Repeat password'
          sx={classes.inlineBlock}
          type='password'
          value={repeatPassword}
          variant='outlined'
          fullWidth
          onChange={(e) => setRepeatPassword(e.target.value)}
        />
        <div>
          <p className='ResetPasswordTooltip'>
            {password.length > 7 ? <SuccessIcon /> : <RedExclamation />}
            Minimum of 8 characters
          </p>
          <p className='ResetPasswordTooltip'>
            {!password.match(/[A-Z]/) || '' ? (
              <RedExclamation />
            ) : (
              <SuccessIcon />
            )}
            At least one uppercase character
          </p>
          <p className='ResetPasswordTooltip'>
            {!password.match(/[0-9]/) || '' ? (
              <RedExclamation />
            ) : (
              <SuccessIcon />
            )}
            At least one number
          </p>
          <p className='ResetPasswordTooltip'>
            {!checkIfStringHasSpecialChar(password) ? (
              <RedExclamation />
            ) : (
              <SuccessIcon />
            )}
            At least one special character (e.g &apos;!&apos; &apos;%&apos;
            &apos;£&apos;)
          </p>
        </div>

        <Button
          label='Confirm'
          onClick={resetPassword}
          disabled={
            !password.match(/[A-Z]/) ||
            !password.match(/[0-9]/) ||
            !checkIfStringHasSpecialChar(password)
          }
          style={{
            background: colors.surface.grey,
            padding: '1rem',
            marginTop: '2rem',
          }}
        >
          <DoneIcon />
        </Button>
      </div>
    </Login>
  );
};

export default ResetPassword;
