import { RESOURCE_CANDIDATES } from '../../provider/restProvider';
import {
  BooleanField,
  BooleanInput,
  DeleteWithConfirmButton,
  Filter,
  FilterProps,
  Link,
  List,
  ListProps,
  NumberField,
  RaRecord,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import ActionsColumn from '../ActionsColumn';
import { PlacementEdit } from './PlacementEdit';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import CustomDateInput from '../CustomDateInput';
import ExportButton from '../ExportButton';
import { FC } from 'react';
import { isEmpty } from '../../provider/UtilityFunctions';
import ArchiveIcon from '@mui/icons-material/Archive';
import FreeTextSearchInput from '../FreeTextSearchInput';
import { commonStyles } from '../CommonStyles';
import { PostPagination } from '../PostPagination';
import moment from 'moment';
import { colors } from '../../theme';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';

const CandidateLinkField: FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();

  return (
    <Link to={`/${RESOURCE_CANDIDATES}/${record?.candidateId}`}>
      <TextField source={source} sortable={false} />
    </Link>
  );
};

const getBackGroundColor = (record: RaRecord) => {
  if (
    moment(record.endDate, 'MMM/DD/YYYY').toDate() < moment().toDate() &&
    record.isActive
  ) {
    return colors.indicator.red;
  }
  if (
    isEmpty(record['onboardingComplete']) ||
    isEmpty(record['drugScreenComplete']) ||
    isEmpty(record['physicalComplete']) ||
    isEmpty(record['trainingComplete']) ||
    isEmpty(record['backgroundCheckComplete']) ||
    isEmpty(record['clientTrainingComplete']) ===
      !record['bypassClientTraining'] //only one should be populated
  ) {
    return colors.indicator.peach;
  }

  return undefined;
};

export const placementRowStyle = (record: RaRecord) => {
  return {
    backgroundColor: getBackGroundColor(record),
  };
};

type PlacementFilterProps = Omit<FilterProps, 'children'>;

const PlacementFilter: FC<PlacementFilterProps> = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <FreeTextSearchInput
        source="searchTerm"
        alwaysOn
        label="Free Text Search"
      />
      <BooleanInput source="IsArchived" label="Archived" alwaysOn />
      <BooleanInput
        source="isPending"
        label="Pending"
        alwaysOn
        format={(v) => Boolean(v)}
      />
      <CustomDateInput source="startDate" label="Start Date" />
      <CustomDateInput source="endDate" label="End Date" />
    </Filter>
  );
};

const PlacementActions: FC = () => {
  return (
    <TopToolbar>
      <PlacementFilter context="button" />
      <ExportButton
        excludedColumns={[
          'candidateId',
          'placementGuid',
          'drugScreenComplete',
          'backgroundCheckComplete',
          'physicalComplete',
          'trainingComplete',
          'onboardingComplete',
          'fieldManagerId',
          'isActive',
          'perDiem',
          'msp',
        ]}
      />
      <SelectColumnsButtonAutoSave />
    </TopToolbar>
  );
};

export const PlacementsList: FC<ListProps> = (props) => {
  return (
    <List
      title={undefined}
      {...props}
      filters={<PlacementFilter />}
      perPage={50}
      pagination={<PostPagination />}
      filterDefaultValues={{
        IsActive: {
          fieldName: 'IsActive',
          fieldValue: 'true',
          condition: '==',
        },
      }}
      actions={<PlacementActions />}
      sort={{ field: 'endDate', order: 'DESC' }}
      sx={commonStyles.raFilterForm}
    >
      <DatagridConfigurableRbac
        bulkActionButtons={false}
        rowClick="expand"
        editComponent={<PlacementEdit syncWithLocation={false} />}
        expandSingle
        rowSx={placementRowStyle}
        sx={commonStyles.defaultGridStyle}
      >
        <NumberField source="id" label="Placement ID" sortable={true} />
        <CandidateLinkField source="firstName" sortable={true} />
        <CandidateLinkField source="lastName" sortable={true} />
        <TextField source="jobTitle" sortable={false} />
        <TextField source="startDate" label="Start" sortable={true} />
        <TextField source="endDate" label="End" sortable={true} />
        <NumberField source="duration" sortable={true} />
        <TextField source="onboardingComplete" sortable={true} />
        <TextField source="backgroundCheckComplete" sortable={true} />
        <TextField source="physicalComplete" sortable={true} />
        <TextField source="drugScreenComplete" sortable={true} />
        <TextField source="trainingComplete" sortable={true} />
        <TextField source="clientTrainingComplete" sortable={true} />
        <BooleanField source="bypassClientTraining" sortable={true} />
        <NumberField source="perDiem" sortable={true} />
        <TextField source="projectName" sortable={true} />
        <TextField source="clientName" sortable={true} />
        <NumberField source="straightTimeBillRate" sortable={true} />
        <NumberField source="overtimeBillRate" sortable={true} />
        <NumberField source="doubleTimeBillRate" sortable={true} />
        <NumberField source="straightTimePayRate" sortable={true} />
        <NumberField source="overtimePayRate" sortable={true} />
        <NumberField source="doubleTimePayRate" sortable={true} />
        <TextField source="rotationId" sortable={true} />
        <TextField source="rotationStartDate" sortable={true} />
        <TextField source="clientContactName" sortable={false} />
        <TextField
          source="fieldManagerName"
          label="Field Manager"
          sortable={true}
        />
        <ActionsColumn
          label="Actions"
          actionsSettings={{ show: false }}
          customDeleteButton={
            <DeleteWithConfirmButton
              label=""
              confirmTitle="Archive Placement"
              confirmContent="Are you sure to archive this item?"
              icon={<ArchiveIcon />}
            />
          }
        />
      </DatagridConfigurableRbac>
    </List>
  );
};
