import {
  AutocompleteInput,
  Datagrid,
  Edit,
  EditProps,
  NumberInput,
  ReferenceManyField,
  required,
  RichTextField,
  SaveButton,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useGetList,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import {
  RESOURCE_CLIENT_COMMENTS,
  RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES,
  RESOURCE_CLIENTS,
  RESOURCE_HIGH_LEVEL_INDUSTRY,
  RESOURCE_LOOKUP_CITY,
  RESOURCE_LOOKUP_COUNTRY,
  RESOURCE_LOOKUP_STATE,
} from '../../provider/restProvider';
import React, { useEffect, useState } from 'react';
import ActionsColumn from '../ActionsColumn';
import { LookupRowForm } from '../Candidate/CandidateCommon';
import { ClientContactList } from '../ClientContacts/ClientContactList';
import { MAX_RECORD_PER_PAGE } from '../../provider/constants';
import CustomAutoCompleteLookup from '../CustomAutoComplete/CustomAutoCompleteLookup';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { commonStyles } from '../CommonStyles';
import { CommentCreate } from '../CommentCreate';
import { DocumentsTab } from './tabs/DocumentsTab';
import { ProjectsTab } from './tabs/ProjectsTab';
import '../CommonCss.css';

export const CLIENT_CONTACTS_TAB_IND = 2;

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Save" variant="text" />
  </Toolbar>
);

export interface ClientEditProps extends Omit<EditProps, 'children'> {
  syncWithLocation: undefined | boolean;
}

export const ClientEdit: React.FC<ClientEditProps> = ({
  syncWithLocation,
  ...props
}) => {
  const { id } = useParams();
  const record = useRecordContext();
  const [clientId, setClientId] = useState(id || record?.id);

  const { data } = useGetOne(RESOURCE_CLIENTS, { id: id || record?.id });
  const [country, setCountry] = useState(
    get(data, 'addresses[0].country', undefined) || 'NoCountry'
  );
  const [state, setState] = useState(
    get(data, 'addresses[0].state', undefined) || 'NoState'
  );

  const { data: countryData, isSuccess: countryLoaded } = useGetList(
    RESOURCE_LOOKUP_COUNTRY,
    {
      pagination: {
        page: 1,
        perPage: MAX_RECORD_PER_PAGE,
      },
    }
  );

  useEffect(() => {
    if (data) {
      setCountry(get(data, 'addresses[0].country', undefined) || 'NoCountry');
      setState(get(data, 'addresses[0].state', undefined) || 'NoState');
      setClientId(data.id);
    }
  }, [data]);

  return (
    <Edit
      {...props}
      title={syncWithLocation === undefined ? '' : ' '}
      actions={false}
      mutationMode="pessimistic"
    >
      <TabbedForm
        warnWhenUnsavedChanges
        toolbar={<EditToolbar />}
        style={{ width: '100%' }}
        syncWithLocation={
          syncWithLocation === undefined ? true : syncWithLocation
        }
        sx={commonStyles.tabbedFormBoldHeader}
      >
        <LazyFormTab label="Header">
          <NumberInput
            source="id"
            label={'Client ID'}
            sx={commonStyles.formComponentDefaultMargins}
            readOnly
          />
          <TextInput
            source="name"
            validate={[required()]}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source={'addresses[0].phone'}
            label={'Phone'}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source={'addresses[0].address'}
            label={'Address'}
            fullWidth={true}
          />
          <AutocompleteInput
            validate={[required()]}
            optionValue="name"
            optionText="name"
            source="addresses[0].country"
            label={'Country'}
            onChange={(item: any) => setCountry(item)}
            choices={countryLoaded ? countryData : []}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomAutoCompleteLookup
            validate={[required()]}
            lookupResource={RESOURCE_LOOKUP_STATE}
            source="addresses[0].state"
            label={'State/Province'}
            onChange={(item: any) => setState(item)}
            parentName={country}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <CustomAutoCompleteLookup
            lookupResource={RESOURCE_LOOKUP_CITY}
            source="addresses[0].city"
            label={'City/Town'}
            parentName={state}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source="addresses[0].zip"
            label={'zip'}
            sx={{
              ...commonStyles.formComponentDefaultMargins,
              marginTop: '8px',
            }}
          />
          <TextInput
            source="fieldManagerCreatedName"
            label={'Created by'}
            disabled={true}
            sx={commonStyles.formComponentDefaultMargins}
          />
          <TextInput
            source="fieldManagedUpdatedName"
            label={'Updated by'}
            disabled={true}
            sx={commonStyles.formComponentDefaultMargins}
          />
        </LazyFormTab>
        <LazyFormTab label="Projects">
          <ProjectsTab clientId={clientId} />
        </LazyFormTab>
        <LazyFormTab label="Contacts">
          <ClientContactList clientId={clientId} />
        </LazyFormTab>
        <LazyFormTab label="Comments">
          <ReferenceManyField
            reference={RESOURCE_CLIENT_COMMENTS}
            target="comments"
            label=""
            perPage={100}
            sort={{ field: 'lastModifiedDate', order: 'DESC' }}
          >
            <Datagrid
              bulkActionButtons={false}
              sx={commonStyles.subGridDefaultStyle}
              rowClick={false}
            >
              <RichTextField source="text" label="Comment" />
              <RichTextField
                source="fieldManagerName"
                label="Comment Authour"
              />
              <TextField source="lastModifiedDate" />
              <ActionsColumn
                label="Actions"
                actionsSettings={{ edit: false, show: false }}
              />
            </Datagrid>
          </ReferenceManyField>

          <CommentCreate resource={RESOURCE_CLIENT_COMMENTS} />
        </LazyFormTab>
        <LazyFormTab label="Documents">
          <DocumentsTab clientId={clientId} />
        </LazyFormTab>
        <LazyFormTab label="Industries">
          <ReferenceManyField
            reference={RESOURCE_CLIENT_HIGH_LEVEL_INDUSTRIES}
            target="highlevelindustry"
            label=""
            perPage={100}
          >
            <EditableDatagrid
              rowClick={false}
              bulkActionButtons={false}
              className={'EditableGridBoldHeader'}
              editForm={
                <LookupRowForm lookupResource={RESOURCE_HIGH_LEVEL_INDUSTRY} />
              }
              createForm={
                <LookupRowForm
                  lookupResource={RESOURCE_HIGH_LEVEL_INDUSTRY}
                  defaultValues={{ clientId }}
                />
              }
              noDelete={true}
            >
              <TextField source="name" label="Industry" sortable={false} />
              <ActionsColumn
                label="Actions"
                actionsSettings={{ edit: false, show: false }}
              />
            </EditableDatagrid>
          </ReferenceManyField>
        </LazyFormTab>
      </TabbedForm>
    </Edit>
  );
};
