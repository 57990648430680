import { ParentMenuItem } from './ParentMenuItem';
import { ResourceMenuItem } from './ResourceMenuItem';
import { ParentMenuItemData, ResourceMenuItemData } from './menuItems';

export const renderMenuItem = (
  menuItem: ResourceMenuItemData | ParentMenuItemData
) => {
  return menuItem.hasOwnProperty('items') ? (
    <ParentMenuItem key={menuItem.id} {...(menuItem as ParentMenuItemData)} />
  ) : (
    <ResourceMenuItem
      key={menuItem.id}
      {...(menuItem as ResourceMenuItemData)}
    />
  );
};
