import React, { ReactElement } from 'react';
import { Edit, maxLength, required, SaveButton, TabbedForm, TextInput, Toolbar } from 'react-admin';

import { commonStyles } from '../CommonStyles';
import { LazyFormTab } from '../LazyFormTab/LazyFormTab';
import { EditPropsWithLocation } from '../types';
import { useFormState } from 'react-hook-form';
import { AuditFormInputs } from '../AuditFields/AuditFormInputs';

const EditToolbar: React.FC = (props) => {
  const { isValid } = useFormState();

  return <Toolbar {...props}>
    <SaveButton
      label="Save"
      variant="text"
      disabled={!isValid}
    />
  </Toolbar>;
};

export const RoleEdit: React.FC<EditPropsWithLocation> = ({
  syncWithLocation = true,
  ...props
}): ReactElement => {
  return (
    <Edit
      {...props}
      title={syncWithLocation === undefined ? "" : " "}
      actions={false}
      mutationMode="pessimistic"
    >
      <>
        <TabbedForm
          warnWhenUnsavedChanges
          toolbar={<EditToolbar/>}
          style={{width: "100%"}}
          syncWithLocation={syncWithLocation}
          sx={commonStyles.tabbedFormBoldHeader}
        >
          <LazyFormTab label="Role">
            <TextInput
              source="name"
              label="Role"
              validate={[required(), maxLength(35)]}
              sx={commonStyles.formComponentDefaultMargins}
            />
            {/*<AuditFormInputs/>*/}
          </LazyFormTab>
        </TabbedForm>
      </>
    </Edit>
  );
}
