import { ResourceMenuItemData } from './menuItems';
import { MenuItemLink } from 'react-admin';

export const ResourceMenuItem = ({
  resource,
  primaryText,
  id,
  leftIcon,
}: ResourceMenuItemData) => (
  <MenuItemLink
    key={id}
    primaryText={primaryText}
    id={id}
    to={`/${resource}`}
    leftIcon={leftIcon}
  ></MenuItemLink>
);
