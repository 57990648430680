import {
  CreateButton,
  Filter,
  FilterProps,
  List,
  ListProps,
  NumberField,
  TextField,
  TopToolbar,
} from 'react-admin';
import ActionsColumn from '../ActionsColumn';
import { ClientEdit } from './ClientEdit';
import React from 'react';
import ExportButton from '../ExportButton';
import FreeTextSearchInput from '../FreeTextSearchInput';
import LinkField from '../LinkField';
import { PostPagination } from '../PostPagination';
import SelectColumnsButtonAutoSave from '../SelectColumnsButtonAutoSave';
import { DatagridConfigurableRbac } from '../DatagridConfigurableRbac/DatagridConfigurableRbac';

// const defaultColumns: string[] = [
//     'ID',
//     'name',
//     'phone',
//     'city',
//     'state',
//     'city',
//     'country',
//     'fieldManagedUpdatedName'
// ];

const ClientActions = () => (
  <TopToolbar>
    <ClientFilter context={'button'} />
    <CreateButton />
    <ExportButton />
    <SelectColumnsButtonAutoSave />
  </TopToolbar>
);

const ClientFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props} variant={'outlined'}>
      <FreeTextSearchInput
        source="searchTerm"
        alwaysOn
        label={'Free Text Search'}
      />
    </Filter>
  );
};

export const ClientList: React.FC<ListProps> = (props) => {
  return (
    <List
      title="Clients"
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<ClientFilter />}
      actions={<ClientActions />}
      perPage={50}
      pagination={<PostPagination />}
    >
      <DatagridConfigurableRbac
        rowClick="expand"
        // optimized={true}
        editComponent={<ClientEdit syncWithLocation={false} />}
        expandSingle={true}
        bulkActionButtons={false}
      >
        <NumberField source="id" label={'Client ID'} />
        <TextField source="name" />
        <LinkField
          phone
          source={'addresses[0].phone'}
          label={'Phone'}
          sortable={false}
        >
          <TextField
            source={'addresses[0].phone'}
            label={'Phone'}
            sortable={false}
          />
        </LinkField>
        <TextField
          source={'addresses[0].address'}
          label={'Address'}
          sortable={false}
        />
        <TextField
          source="addresses[0].city"
          label={'City/Town'}
          sortable={false}
        />
        <TextField
          source="addresses[0].state"
          label={'State'}
          sortable={false}
        />
        <TextField
          source="addresses[0].country"
          label={'Country'}
          sortable={false}
        />
        <TextField
          source="fieldManagerCreatedName"
          label={'Created by'}
          sortable={false}
        />
        <TextField
          source="fieldManagedUpdatedName"
          label={'Updated by'}
          sortable={false}
        />

        <ActionsColumn label="Actions" actionsSettings={{ show: false }} />
      </DatagridConfigurableRbac>
    </List>
  );
};
